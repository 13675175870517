import { Component, OnInit } from '@angular/core';
import * as L from 'leaflet';
import { EinsatzmittelService } from 'src/app/services/einsatzmittel.service';
import { OrtungService } from 'src/app/services/ortung.service';

const iconRetinaUrl = 'assets/marker-icon-2x.png';
const iconUrl = 'assets/marker-icon.png';
const shadowUrl = 'assets/marker-shadow.png';
const iconDefault = L.icon({
  iconRetinaUrl,
  iconUrl,
  shadowUrl,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  tooltipAnchor: [16, -28],
  shadowSize: [41, 41]
});
L.Marker.prototype.options.icon = iconDefault;

@Component({
  selector: 'app-lagekarte',
  templateUrl: './lagekarte.component.html',
  styleUrls: ['./lagekarte.component.css']
})
export class LagekarteComponent implements OnInit {
  map
  markers = {};



  constructor(
    private einsatzmittelService: EinsatzmittelService, 
    private ortungService:        OrtungService,
  ) { 
    
    
  }

  ngOnInit() {
    this.map = L.map('lagekarte', {drawControl: true}).setView([48.0152, 11.0769], 13);


L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
}).addTo(this.map);




//get list of all einheiten

this.einsatzmittelService.getEntriesForTracking().subscribe(data => {
  data.forEach((element: any) => {

    //create markers
    this.markers[element.payload.doc.id] = new L.marker([48,11]).addTo(this.map)
    .bindPopup(element.payload.doc.data().funkrufname, {autoClose:false})
    .openPopup();
    let einheitId = element.payload.doc.id
    //get positionData
    this.ortungService.getLastWaypointforEinheit(element.payload.doc.id).subscribe((result:any) => {
      console.log(result);
      
      result.forEach(element => {
        console.log(element.payload.doc.data().position);
        let newLatLng = new L.LatLng(element.payload.doc.data().position.O, element.payload.doc.data().position.F);
        this.markers[einheitId].setLatLng(newLatLng); 
      });
      

    })

  });
})



  }
  

}
