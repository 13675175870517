import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EinsaetzeEinsatzartenService {

  constructor() { }

  getEntries(){
    return ["Lebensrettung", "Medizinischer Notfall"];
  }
}
