import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FahrtenbuchComponent } from './components/fahrtenbuch/fahrtenbuch.component';
import { MatAutocompleteModule, MatAutocomplete } from "@angular/material/autocomplete";
import { MatBadgeModule } from "@angular/material/badge";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckbox, MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatNavList, MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioButton, MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenav, MatSidenavModule } from "@angular/material/sidenav";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSliderModule } from "@angular/material/slider";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbar, MatToolbarModule } from "@angular/material/toolbar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EinsatzkraefteService } from './services/einsatzkraefte.service';
import { EinsatzmittelService } from './services/einsatzmittel.service';
import { FahrtenbuchFormComponent } from './components/fahrtenbuch-form/fahrtenbuch-form.component';

import { FahrtenbuchTableComponent } from './components/fahrtenbuch-table/fahrtenbuch-table.component';

import { AngularFirestoreModule, AngularFirestore } from 'angularfire2/firestore';
import { AngularFireStorageModule } from 'angularfire2/storage'
import { AngularFireModule } from 'angularfire2';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { environment } from '../environments/environment';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';



import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { DatePipe, CommonModule } from '@angular/common'
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { AngularMaterialModule } from './angular-material.module';
import { FahrtenbuchMapComponent } from './components/fahrtenbuch-map/fahrtenbuch-map.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { LayoutModule } from '@angular/cdk/layout';
import { LagekarteComponent } from './components/lagekarte/lagekarte.component';
import { WachbuchComponent } from './components/wachbuch/wachbuch.component';
import { WachbuchTableComponent } from './components/wachbuch-table/wachbuch-table.component';
import { EinsaetzeComponent } from './components/einsaetze/einsaetze.component';
import { EinsaetzeTableComponent } from './components/einsaetze-table/einsaetze-table.component';
import { WachbuchFormComponent } from './components/wachbuch-form/wachbuch-form.component';
import { EinsaetzeFormComponent } from './components/einsaetze-form/einsaetze-form.component';
import { OrganisationSelectorComponent } from './components/organisation-selector/organisation-selector.component';

import { FlexLayoutModule } from '@angular/flex-layout';
import { AuthGuard } from './auth.guard';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AdminComponent } from './components/admin/admin.component';
import {MatExpansionModule} from '@angular/material/expansion';
import { AnQrcodeModule } from 'an-qrcode';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import { OpenQrBottomSheetComponent } from './components/open-qr-bottom-sheet/open-qr-bottom-sheet.component';
import { ProfileComponent } from './components/profile/profile.component';
import { OrtungComponent } from './components/ortung/ortung.component';






@NgModule({
  declarations: [
    AppComponent,
    FahrtenbuchComponent,
    FahrtenbuchFormComponent,

    FahrtenbuchTableComponent,
    LoginComponent,
    RegisterComponent,



    FahrtenbuchMapComponent,

    NavigationComponent,

    LagekarteComponent,


    WachbuchComponent,

    WachbuchTableComponent,

    EinsaetzeComponent,

    EinsaetzeTableComponent,

    WachbuchFormComponent,

    EinsaetzeFormComponent,

    OrganisationSelectorComponent,



    ResetPasswordComponent,

    AdminComponent,

    OpenQrBottomSheetComponent,

    ProfileComponent,

    OrtungComponent,





  ],
  imports: [
    BrowserModule,
    AppRoutingModule,

    MatIconModule,
    MatInputModule,
    MatSliderModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressSpinnerModule,
    BrowserAnimationsModule,
    MatCardModule, 
    MatButtonModule,
    MatAutocompleteModule,
    MatGridListModule,
    MatMenuModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    AngularFireAuthModule, 
    MatToolbarModule,
    MatSidenavModule,
    ReactiveFormsModule,
    FormsModule,
    MatRadioModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    BrowserModule, 
    OwlDateTimeModule, 
    OwlNativeDateTimeModule,
    MatSidenavModule,
    CommonModule,
    MatButtonModule,
    MatToolbarModule,
    MatIconModule,
    MatSidenavModule,
    MatBadgeModule,
    MatListModule,
    MatGridListModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatRadioModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatChipsModule,
    MatTooltipModule,
    MatTableModule,
    MatPaginatorModule,
    MatCardModule,
    AngularMaterialModule,
    LayoutModule,
    MatTabsModule,
    FlexLayoutModule,
    AngularFireStorageModule,
    HttpClientModule,
    MatExpansionModule,
    AnQrcodeModule,
    MatBottomSheetModule,



  ],
  providers: [
    EinsatzkraefteService,
    EinsatzmittelService,
    DatePipe,
    AuthGuard,
    AngularFirestore,
    {provide: OWL_DATE_TIME_LOCALE, useValue: 'de'},
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
