



<mat-toolbar color="primary">
       <mat-toolbar-row>
         <span class="example-spacer"></span>
         <span >Fahrtenbuch: Details</span>
         <span class="example-spacer"></span>
         
       </mat-toolbar-row>
       
     </mat-toolbar>
     <button mat-fab (click)="showTable.emit();" class="main-fab"><mat-icon>arrow_back_ios</mat-icon></button>
     <mat-card class="formCard">
       <mat-card-content>
             
 <!--test-->
 <br>
 <br>
 <div fxLayout="column" style="width: 100%;">
       <form [formGroup]="form"  >
              <div fxLayout.xs="column" fxLayout="row" >
                     <mat-form-field fxFlex.gt-sm="25%">
                            <mat-label>Einsatzmittel</mat-label>
                            <mat-select formControlName="einsatzmittel">
                                   <mat-option *ngFor="let item of einsatzmittel | async" [value]="item.payload.doc.id">
                                   {{item.payload.doc.data().funkrufname}}
                                   </mat-option>
                            </mat-select>
                     </mat-form-field>
                     
                     <mat-form-field fxFlex.gt-sm="25%">
                            <mat-label>Kategorie</mat-label>
                            <mat-select formControlName="kategorie">
                                   <mat-option *ngFor="let item of kategorien; index as i" [value]="i">
                                   {{item}}
                                   </mat-option>
                            </mat-select>
                     </mat-form-field>
                     
                     <mat-form-field fxFlex.gt-sm="25%">
                            <mat-label>Beginn</mat-label>
                            <input matInput type="datetime-local" formControlName="beginn" >
                            <mat-error *ngIf="form.get('beginn').invalid ">Format: 01.12.2020, 19:30</mat-error>
                     </mat-form-field>
                     <mat-form-field *ngIf="kategorie == 1" fxFlex.gt-sm="25%"> 
                            <mat-label>Ende</mat-label>
                            <input matInput type="datetime-local" formControlName="ende" >
                            <mat-error *ngIf="form.get('ende').invalid ">Format: 01.12.2020, 19:30</mat-error>
                     </mat-form-field>

                            <mat-slide-toggle *ngIf="kategorie == 3" formControlName="benachrichtigungVerantwortlicher">TL informieren</mat-slide-toggle>

              </div>
              <div fxLayout.xs="column" fxLayout="row" >
                     <mat-form-field fxFlex.gt-sm="25%">
                            <mat-label>Fahrzeugführer</mat-label>
                            <mat-select formControlName="fahrzeugfuehrer">
                                   <mat-option *ngFor="let item of einsatzkraefte | async" [value]="item.payload.doc.id">
                                   {{item.payload.doc.data().vorname}} {{item.payload.doc.data().name}}
                                   </mat-option>
                            </mat-select>
                     </mat-form-field>
                     <mat-form-field *ngIf="kategorie == 1" fxFlex.gt-sm="25%">
                            <mat-label>Kilometerstand/Betriebsstunden Beginn</mat-label>
                            <input matInput placeholder="Kilometerstand/Betriebsstunden Beginn" formControlName="kilometerstand_von">
                     </mat-form-field>
                     <mat-form-field *ngIf="kategorie == 1" fxFlex.gt-sm="25%">
                            <mat-label>Kilometerstand/Betriebsstunden Ende</mat-label>
                            <input matInput placeholder="Kilometerstand/Betriebsstunden Ende" formControlName="kilometerstand_bis">
                     </mat-form-field>
                     <mat-slide-toggle *ngIf="kategorie == 1" formControlName="alarmfahrt">Alarmfahrt</mat-slide-toggle>
              </div>
              <div fxLayout.xs="column" fxLayout="row" >
                     <mat-form-field *ngIf="kategorie == 0" fxFlex.gt-sm="50%">
                            <mat-label>Menge Kraftstoff [l]</mat-label>
                            <input matInput placeholder="Menge Kraftstoff [l]" formControlName="mengeKraftstoff">
                     </mat-form-field>
                     <mat-form-field *ngIf="kategorie == 0" fxFlex.gt-sm="50%">
                            <mat-label>Menge Öl [l]</mat-label>
                            <input matInput placeholder="Menge Öl [l]" formControlName="mengeOel">
                     </mat-form-field>
              </div>
              <div fxLayout.xs="column" fxLayout="row" >
                     <mat-form-field fxFlex.gt-sm="100%">
                            <mat-label>Bemerkung</mat-label>
                            <textarea matInput rows="4" placeholder="bemerkung" formControlName="bemerkung"></textarea>
                     </mat-form-field>
              </div>
       </form>
       <div *ngIf="kategorie == 1">
       <h2>Besatzung</h2>
       </div>
       <form *ngIf="kategorie == 1" [formGroup]="form">
              
              <br>
              <div formArrayName="besatzung" >
                     <div *ngFor="let entry of besatzungForm.controls; let i=index" [formGroupName]="i">                     
                        <mat-form-field fxFlex.gt-sm="95%">
                               <mat-label>Name</mat-label>
                               <mat-select formControlName="einsatzkraftKey">
                                      <mat-option *ngFor="let item of einsatzkraefte | async" [value]="item.payload.doc.id">
                                      {{item.payload.doc.data().vorname}} {{item.payload.doc.data().name}}
                                      </mat-option>
                               </mat-select>
                        </mat-form-field>
                    
                        <button mat-raised-button fxFlex.gt-sm="5%" color="warn" (click)="deleteEinsatzkraft(i)"><mat-icon>delete</mat-icon></button>
                     </div>
              </div>
      
       </form>
       <div align="center">
              <button *ngIf="kategorie == 1" mat-raised-button color="secondary" (click)="addEinsatzkraft()">Einsatzkraft hinzufügen</button> 
              </div>
       <!--
              Karte erstmal ausblenden
<div fxShow fxHide.xs="true">
              <app-fahrtenbuch-map [fahrtenbuchEntryKey]="entryId" class="map"></app-fahrtenbuch-map>
       </div>
       -->
       
       
</div>
</mat-card-content>
</mat-card>





