import { Component } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay, take } from 'rxjs/operators';
import { OrganisationsService } from 'src/app/services/organisations.service';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent {
  
  currentOrganisation:string;
  photoURL:string;
  displayName:string;


  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(
    private breakpointObserver: BreakpointObserver,
    private organisationsService: OrganisationsService,
    public auth: AuthService,
    private router: Router
    ) {

      
    var organisationKey = localStorage.getItem("currentOrganisation");
    this.auth.user$.subscribe(data => {
      this.photoURL = data.photoURL
      this.displayName = data.displayName
    })
    this.organisationsService.getSingleEntry(organisationKey).pipe(take(1)).subscribe((result: any) => {
      this.currentOrganisation = result.payload.data().name
    })
  }
  showOrganisationsSelect() {
    this.router.navigate(['organisations']); 
  }
  openTrackingModule() {
    this.router.navigate(['ortung']); 
  }

}
