import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter, OnDestroy } from '@angular/core';
import { WachdiensteService } from 'src/app/services/wachdienste.service';
import { EinsatzkraefteService } from 'src/app/services/einsatzkraefte.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-wachbuch-table',
  templateUrl: './wachbuch-table.component.html',
  styleUrls: ['./wachbuch-table.component.css']
})
export class WachbuchTableComponent implements OnInit {




displayedData;

  displayedColumns: string[] = ['dienstbeginn', 'dienstende', 'wachleiter', 'star'];
  showFilterInputValue



  constructor(
    private einsatzkraefteService: EinsatzkraefteService, 
    private wachdiensteService: WachdiensteService
  ) { 
    
}
  
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild("filterField", {static: true}) filterField: ElementRef;

  @Output() editEntry = new EventEmitter<string>();
  
  ngOnInit(): void{

    this.displayedData = this.wachdiensteService.getJoinedEntries()
    this.initSort();
    
  }

  initSort(){
    this.displayedData.sort = this.sort;
    this.displayedData.paginator = this.paginator;
  }
  newEntry() {
    let data = {
      dienstbeginn: '',
      anmeldungRLST: '',
      abmeldungRLST: '',
      dienstende: '',
      wachleiter: '',
      wetterlage: '',
      lufttemperatur: '',
      wassertemperatur: '',
      bemerkung: '',
      einsatzkraefte: [],
    }
    let key = this.wachdiensteService.createEntry(data)
    this.editEntry.emit(key)
  }
  deleteEntry(key){
    this.wachdiensteService.deleteEntry(key);
  }
  showFilterInput(){
    this.showFilterInputValue = true;
    this.filterField.nativeElement.focus()
  }
  closeFilterInput(){
    this.showFilterInputValue = false;
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.displayedData.filter = filterValue.trim().toLowerCase();
  }
}
