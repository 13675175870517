import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EinsaetzeAlarmiererService {

  constructor() { }
  getEntries(){
    return ["Feuerwehr", "ILS"];
  }
}
