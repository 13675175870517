import { Component, OnInit, Inject } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import {MAT_BOTTOM_SHEET_DATA} from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-open-qr-bottom-sheet',
  templateUrl: './open-qr-bottom-sheet.component.html',
  styleUrls: ['./open-qr-bottom-sheet.component.css']
})
export class OpenQrBottomSheetComponent  {


  constructor(
    private _bottomSheetRef: MatBottomSheetRef<OpenQrBottomSheetComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any

  ) {

  }

  openLink(event: MouseEvent): void {
    this._bottomSheetRef.dismiss();
    event.preventDefault();
  }

}
