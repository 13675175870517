<mat-sidenav-container class="sidenav-container">
  <mat-sidenav-content>
    <mat-toolbar color="primary">
      <span>RescueDesk</span>
      <span class="example-spacer"></span>
      <button mat-icon-button (click)="auth.signOut()"><mat-icon aria-label="Side nav toggle icon">mail</mat-icon></button>
      <button mat-icon-button (click)="auth.signOut()"><mat-icon aria-label="Side nav toggle icon">exit_to_app</mat-icon></button>
      </mat-toolbar>
    <!-- Content -->
    <mat-grid-list cols="3" fxHide="false" fxHide.xs="true">
      <mat-grid-tile *ngFor="let item of organisationsData">
        <mat-card class="example-card" >
          <mat-card-header>
            <img mat-card-avatar src="{{ item.logo }}">
            <mat-card-title>{{ item.name }}</mat-card-title>
          </mat-card-header>
          <img mat-card-image src="{{ item.header }}" style="height: 70%;">
          <mat-card-actions style="text-align: center;">
              <button  mat-raised-button (click)="setCurrentOrganisation(item.organisationKey)" color="primary">Auswählen</button>
          </mat-card-actions>
        </mat-card>
      </mat-grid-tile>
    </mat-grid-list>

    <mat-list fxHide="true" fxHide.xs="false">
      <mat-list-item *ngFor="let item of organisationsData" (click)="setCurrentOrganisation(item.organisationKey)"><img mat-card-avatar src="{{ item.logo }}"> {{ item.name }} </mat-list-item>

     </mat-list>
    <!-- Content -->
  </mat-sidenav-content>
</mat-sidenav-container>











  