import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EinsatzkraefteFunktionenService {

  constructor() { }
  getEntries(){
    return ["Wachleiter", "Wasserretter", "Bootsführer", "Rettungsschwimmer im WRD"];
  }
}
