
<app-navigation>
    <mat-toolbar color="primary">
        <mat-toolbar-row>
          <span class="example-spacer"></span>
          <span >Lagekarte</span>
          <span class="example-spacer"></span>
        </mat-toolbar-row>
      </mat-toolbar>

        <div id="lagekarte"></div>


        
    
</app-navigation>

