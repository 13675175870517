import { Injectable } from '@angular/core';
import { Router } from  "@angular/router";
import * as firebase from  'firebase/app';
import { AngularFireAuth } from  "angularfire2/auth";
import { AngularFirestore, AngularFirestoreDocument } from "angularfire2/firestore";
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { User } from '../interfaces/user.model'



@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user$: Observable<User>;

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router
  ) { 
    this.user$ = this.afAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          return this.afs.doc<User>(`users/${user.uid}`).valueChanges();
        } else {
          // Logged out
          return of(null);
        }
      }
    ))
    

  }
  async googleSignin() {
    const provider = new firebase.auth.GoogleAuthProvider();
    const credential = await this.afAuth.auth.signInWithPopup(provider);
    this.router.navigate(['organisations']);   
    return this.updateUserData(credential.user);
  }
  async githubSignin() {
    const provider = new firebase.auth.GithubAuthProvider
    const credential = await this.afAuth.auth.signInWithPopup(provider);
    this.router.navigate(['organisations']);   
    return this.updateUserData(credential.user);
  }
  async emailSignin(email, password) {
    const credential = await this.afAuth.auth.signInWithEmailAndPassword(email, password) 
    this.router.navigate(['organisations']);   

    return this.updateUserData(credential.user)
  }
  public updateUserData(user) {
    // Sets user data to firestore on login
    const userRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${user.uid}`);

    const data = { 
      uid: user.uid, 
      email: user.email, 
      displayName: user.displayName, 
      photoURL: user.photoURL
    } 

    return userRef.set(data, { merge: true })

  }
  async signOut() {
    await this.afAuth.auth.signOut();
    localStorage.removeItem('currentOrganisation');
    this.router.navigate(['login']); 
  }

  async register(email, password) {
    const credential = await this.afAuth.auth.createUserWithEmailAndPassword(email, password); 
    this.updateUserData(credential.user);
    credential.user.sendEmailVerification();  
    this.router.navigate(['organisations']);  
  }

  async sendPasswordResetEmail(passwordResetEmail: string) {
    return await this.afAuth.auth.sendPasswordResetEmail(passwordResetEmail);
  }





}
