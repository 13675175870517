<app-navigation>
    <mat-toolbar color="primary">
        <mat-toolbar-row>
          <span class="example-spacer"></span>
          <span >Admin</span>
          <span class="example-spacer"></span>
          
        </mat-toolbar-row>
        
      </mat-toolbar>

    <mat-card class="formCard">
        <mat-card-content>
            <mat-tab-group>
                <mat-tab label="Allgemein"> 
                    <mat-list>
                        <mat-list-item>Name</mat-list-item>
                            <form [formGroup]="form" style="width: 80%;">
                                <mat-form-field style="width: 100%;">
                                    <mat-label>Name</mat-label>
                                    <input matInput  formControlName="name">
                                </mat-form-field>
                            </form>
                        <mat-divider></mat-divider>
                        <mat-list-item>Logo</mat-list-item>
                            <img mat-card-avatar src="{{ logoURL }}">
                            <input (change)="updateLogo($event)" type='file' />
                        <mat-divider></mat-divider>
                        <mat-list-item>Header</mat-list-item>
                            <img style="width: 100px;" src="{{ headerURL }}">
                            <input (change)="updateHeader($event)" type='file' />
                      </mat-list>

                            
            

                    
                </mat-tab>
                <mat-tab label="Einheiten">

           <br>
                    
                    <form [formGroup]="form" style="width: 100%;">
                        <div formArrayName="einheiten">
                               <div *ngFor="let entry of einheitenForm.controls; let i=index" [formGroupName]="i">
                               <mat-form-field >
                                      <mat-label>Funkrufname</mat-label>
                                      <input matInput  formControlName="funkrufname">
                               </mat-form-field>
                               <mat-form-field >
                                <mat-label>ISSI</mat-label>
                                <input matInput  formControlName="issi">
                         </mat-form-field>
                         <mat-form-field >
                            <mat-label>Kennzeichen</mat-label>
                            <input matInput  formControlName="kennzeichen">
                            
                     </mat-form-field>
                         <mat-form-field >
                            <mat-label>Typ</mat-label>
                            <mat-select formControlName="type">
                                   <mat-option [value]=0>Boot</mat-option>
                                   <mat-option [value]=1>Kfz</mat-option>
                                   <mat-option [value]=2>Trupp</mat-option>
                            </mat-select>
                     </mat-form-field>
                               
                               <mat-form-field *ngIf="entry.value.enableOrtung" >
                                    <mat-label>Ortung Secret</mat-label>
                                    <input matInput  formControlName="ortungSecret">
                                </mat-form-field>
                                
                             
                               <mat-form-field >
                                      <mat-label>Bemerkung</mat-label>
                                      <input matInput  formControlName="bemerkung">
                               </mat-form-field>
                               <mat-checkbox formControlName="enableOrtung">Ortung erlauben</mat-checkbox>
                               <button mat-raised-button color="primary" (click)="showQR(entry.getRawValue().ortungSecret)">QR</button>
                               <button mat-raised-button color="warn" (click)="deleteEinheit(i)">löschen</button>


                               
                               </div>
                        </div>
                        <div align="center">
                            
                            <button mat-raised-button color="primary" (click)="addEinheit()">Einheit hinzufügen</button>
                        </div>
                        
                 </form>
                 
                </mat-tab>
                <mat-tab label="Einsatzkräfte">
<br>
                    
                    <form [formGroup]="form" style="width: 100%;">
                        <div formArrayName="einsatzkraefte">
                               <div *ngFor="let entry of einsatzkraefteForm.controls; let i=index" [formGroupName]="i">
                                <mat-form-field >
                                    <mat-label>Vorname</mat-label>
                                    <input matInput  formControlName="vorname">
                                </mat-form-field>
                                <mat-form-field >
                                      <mat-label>Name</mat-label>
                                      <input matInput  formControlName="name">
                               </mat-form-field>
                               <mat-form-field>
                                    <mat-label>Qualifikationen</mat-label>
                                    <mat-select formControlName="qualifikationen" multiple>
                                     <mat-option [value]=0 >Bootführer</mat-option>
                                     <mat-option [value]=1 >Wachleiter</mat-option>
                                     <mat-option [value]=2 >Wasserretter</mat-option>
                                     <mat-option [value]=3 >Notarzt</mat-option>
                                </mat-select>
                              </mat-form-field>


                               
                               <button mat-raised-button color="warn" (click)="deleteEinsatzkraft(i)">löschen</button>


                               
                               </div>
                        </div>
                        <div align="center">
                            
                            <button mat-raised-button color="primary" (click)="addEinsatzkraft()">Einsatzkraft hinzufügen</button>
                        </div>
                        
                 </form>
                </mat-tab>
                <mat-tab label="Benutzer">
                    <br>
       
                    <form [formGroup]="form" style="width: 100%;">
                        <div formArrayName="benutzer">
                               <div *ngFor="let entry of benutzerForm.controls; let i=index" [formGroupName]="i">
                                <mat-form-field >
                                    <mat-label>E-Mail</mat-label>
                                    <input matInput  formControlName="email">
                                </mat-form-field>
                                <mat-form-field >
                                      <mat-label>Bemerkung</mat-label>
                                      <input matInput  formControlName="bemerkung">
                               </mat-form-field>
                               <mat-form-field>
                                    <mat-label>Benutzergruppen</mat-label>
                                    <!--
                                        *
                                        * Hier fehlt noch die richtige Integration der Benutzergruppen
                                        *
                                    -->
                                    <mat-select formControlName="gruppen" multiple>
                                     <mat-option *ngFor="let item of gruppenForm.value" [value]="item.bezeichnung" >{{ item.bezeichnung }}</mat-option>
                                </mat-select>
                              </mat-form-field>


                               
                               <button mat-raised-button color="warn" (click)="deleteBenutzer(i)">löschen</button>


                               
                               </div>
                        </div>
                        <div align="center">
                            
                            <button mat-raised-button color="primary" (click)="addBenutzer()">Benutzer hinzufügen</button>
                        </div>
                        
                 </form>
                </mat-tab>
                <mat-tab label="Benutzergruppen">
                    <br>
       
                    <form [formGroup]="form" style="width: 100%;">
                        <div formArrayName="benutzergruppen">
                               <div *ngFor="let entry of gruppenForm.controls; let i=index" [formGroupName]="i">
                                <mat-form-field >
                                    <mat-label>Bezeichnung</mat-label>
                                    <input matInput  formControlName="bezeichnung">
                                </mat-form-field>
                                <mat-form-field >
                                      <mat-label>Bemerkung</mat-label>
                                      <input matInput  formControlName="bemerkung">
                               </mat-form-field>
                               <mat-form-field>
                                    <mat-label>Berechtigungen</mat-label>
                                    <mat-select formControlName="berechtigungen" multiple>
                                     <mat-option value="fahrtenbuch" >Fahrtenbuch</mat-option>
                                     <mat-option value="map" >Lagekarte</mat-option>
                                     <mat-option value="wachbuch" >Wachbuch</mat-option>
                                     <mat-option value="einsaetze" >Wachbuch</mat-option>
                                     <mat-option value="admin" >Admin</mat-option>
                                </mat-select>
                              </mat-form-field>


                               
                               <button mat-raised-button color="warn" (click)="deleteGruppe(i)">löschen</button>


                               
                               </div>
                        </div>
                        <div align="center">
                            
                            <button mat-raised-button color="primary" (click)="addGruppe()">Benutzergruppe hinzufügen</button>
                        </div>
                        
                 </form>
                </mat-tab>
              </mat-tab-group>
        </mat-card-content>
    </mat-card>

</app-navigation>