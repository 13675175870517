
  <div style=" background-image: url('assets/img/loginbackground.jpg'); width: 100%; height: 100%; top: 0px; position: fixed;">
    <mat-card style="top: 25%;">
      <mat-card-title>RescueDesk</mat-card-title>
      <mat-card-content>
        
        <form [formGroup]="form"  >
          <div fxLayout="column" >
            <mat-form-field fxFlex.gt-sm="100%">
              <mat-label>E-Mail</mat-label>
              <input matInput type="email" formControlName="email" >
            </mat-form-field>
            <button mat-raised-button color="primary" (click)="reset()">Passwort zurücksetzen</button>
            <br>
            <p>
              <a href="login" >Login</a> | <a href="register" >neues Benutzerkonto</a> 
            </p>
            <br>
          <mat-divider></mat-divider>
          <br>
          Dies ist eine Entwickler-Version von RescueDesk und nur für den internen Test-Betrieb gedacht. Fragen: marcus.mueller[at]wasserwacht-utting.de
          </div>
        </form>
        
        

        
      </mat-card-content>
    </mat-card>
  </div>








