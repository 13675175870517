<app-navigation>
    <mat-toolbar color="primary">
        <mat-toolbar-row>
          <span class="example-spacer"></span>
          <span >Mein Profil</span>
          <span class="example-spacer"></span>
          
        </mat-toolbar-row>
        
      </mat-toolbar>
    <mat-card class="formCard">
        <mat-card-content>
            <form [formGroup]="form" style="width: 100%;">
                <mat-form-field style="width: 100%;">
                    <mat-label>E-Mail</mat-label>
                    <input matInput  formControlName="email">
                </mat-form-field>
                <br>
                <mat-form-field style="width: 100%;">
                    <mat-label>Name</mat-label>
                    <input matInput  formControlName="displayName">
                </mat-form-field>
                <br>
                <img mat-card-avatar src="{{ form.value.photoURL }}">
                <br>
                <input (change)="updateLogo($event)" type='file' />
            </form>
            <br>
        </mat-card-content>
    </mat-card>
</app-navigation>