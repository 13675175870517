import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { EinsatzmittelService } from 'src/app/services/einsatzmittel.service';
import { OrtungService } from 'src/app/services/ortung.service';

@Component({
  selector: 'app-ortung',
  templateUrl: './ortung.component.html',
  styleUrls: ['./ortung.component.css']
})
export class OrtungComponent implements OnInit {

  constructor(
    private formBuildService: FormBuilder,
    private einheitenService: EinsatzmittelService,
    private ortungService: OrtungService,
  ) { }


  einheiten
  trackingActivated = false;
  watchID
  oldLat = 0.0;
  oldLon = 0.0;

  form: FormGroup = this.formBuildService.group({
    selectedEinheit: ['', [
      Validators.required,
    ]], 
    lat: [{
      value: null,
      disabled: true
    }],
    lon: [{
      value: null,
      disabled: true
    }],
    timestamp: [{
      value: null,
      disabled: true
    }],
  });

  ngOnInit(): void {


    this.einheiten = this.einheitenService.getEntriesForTracking();



  }



  startTracking() {
    this.trackingActivated = true;
    if (navigator.geolocation) {
      this.watchID = navigator.geolocation.watchPosition(position => {
        const einheit = this.form.value.selectedEinheit;
        const lat = position.coords.latitude;
        const lon = position.coords.longitude;
        const timestamp = position.timestamp;
  
        //Teste welche Entfernung zurückgelegt wurde
        const deltaLat = lat - this.oldLat
        const deltaLon = lon - this.oldLon
        const distance = Math.sqrt(deltaLat*deltaLat + deltaLon*deltaLon)
        if (distance >= 0.05) {//dringend Ändern
          this.form.patchValue({
            lat: lat,
            lon: lon,
            timestamp:  timestamp,
          })
          this.ortungService.setNewWaypoint(einheit, lat, lon, timestamp)
          console.log("Position übertragen");
          
          this.oldLat = lat;
          this.oldLon = lon;
        }
      })
    } else {
      console.log("Funktion wird nicht unterstützt");
      
    }
  }
  stopTracking() {
    this.trackingActivated = false;
    navigator.geolocation.clearWatch(this.watchID);
    this.form.patchValue({
      lat: "",
      lon: "",
      timestamp:  "",
    })
  }

}
