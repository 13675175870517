import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, Validators, FormControl } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { FirebaseStorage } from 'angularfire2';
import { take } from 'rxjs/operators';
import { AngularFireStorage } from 'angularfire2/storage';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  form: FormGroup = this.formBuildService.group({
    uid: '', 
    email: '', 
    displayName: '', 
    photoURL: '',
  },{ updateOn: 'blur' })

  pauseForm: boolean = false;
  formSubscription: Subscription
  authSubscription: Subscription
  
  constructor(
    private formBuildService: FormBuilder,
    private authService: AuthService,
    private storageService: AngularFireStorage,
  ) {
    this.authSubscription = this.authService.user$.subscribe((data:any)=>{
      this.pauseForm = true;
      this.form.patchValue(data)
      this.pauseForm = false;
    })
    this.formSubscription =  this.form.valueChanges.subscribe(data => {
      if (this.pauseForm == false && this.form.valid) {
        console.log("save");
        this.saveChanges();
      } else {
        console.log("not save");
        
      }
    })
    
  }

  ngOnInit(): void {
  }
  saveChanges() {
    this.authService.updateUserData(this.form.value)
  }
  updateLogo(event) {
    const path = "user_photo/"+this.form.value.uid+".png"
    const ref = this.storageService.ref(path)
    ref.put(event.target.files[0]).then(result => {
      this.storageService.ref(path).getDownloadURL().pipe(take(1)).subscribe((url: string) => {
        this.form.patchValue({photoURL: url})
        this.saveChanges()
      })
    })
  }
  ngOnDestroy(){
    this.formSubscription.unsubscribe()
    this.authSubscription.unsubscribe()
  }

}
