import { Injectable } from '@angular/core';
import { AngularFirestore } from 'angularfire2/firestore';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OrganisationsService {




  constructor(private firestore: AngularFirestore) { }

  getSingleEntry(key){   
    return this.firestore.collection("organisationen").doc(key).snapshotChanges();
  }
  getEntries() { 
    return this.firestore.collection("organisationen").snapshotChanges();
  }
  updateEntry(key, data) {
    return this.firestore.collection("organisationen").doc(key).update(data);
  }


}
