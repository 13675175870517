<mat-toolbar color="primary">
    <mat-toolbar-row>
      <span class="example-spacer"></span>
      <span >Fahrtenbuch: Details</span>
      <span class="example-spacer"></span>
      
    </mat-toolbar-row>
    
  </mat-toolbar>
  <button mat-fab (click)="showTable.emit();" class="main-fab"><mat-icon>arrow_back_ios</mat-icon></button>



           <mat-card class="formCard">
                  <mat-card-content >
                         <div *ngIf="metaStatus == 0">
                            <mat-tab-group>
                                   <mat-tab label="Details">
                                          <br>
                                          <div fxLayout="column" style="width: 100%;">
                                                 <form [formGroup]="form" style="width: 100%;">
                                                        <div fxLayout.xs="column" fxLayout="row" >
                                                               <mat-form-field fxFlex.gt-sm="25%">
                                                                      <mat-label>Alarmzeit</mat-label>
                                                                      <input  matInput  formControlName="einsatzdaten_alarmzeit">
                                                                      <mat-error *ngIf="form.get('einsatzdaten_alarmzeit').invalid ">Format: 01.12.2020, 19:30</mat-error>
                                                               </mat-form-field> 
                                                               <mat-form-field fxFlex.gt-sm="25%">
                                                                      <mat-label>Einsatzende</mat-label>
                                                                      <input  matInput  formControlName="einsatzdaten_endezeit">
                                                                      <mat-error *ngIf="form.get('einsatzdaten_endezeit').invalid ">Format: 01.12.2020, 19:30</mat-error>
                                                               </mat-form-field>
                                                               <mat-form-field fxFlex.gt-sm="25%">
                                                                      <mat-label>Einsatzart</mat-label>
                                                                      <mat-select formControlName="einsatzdaten_einsatzart">
                                                                             <mat-option *ngFor="let item of einsatzarten ; let i=index" [value]="i">
                                                                                    {{item}}
                                                                             </mat-option>
                                                                      </mat-select>
                                                               </mat-form-field>                                          
                                                        </div>
                                                        <div fxLayout.xs="column" fxLayout="row" >
                                                               <mat-form-field fxFlex.gt-sm="25%">
                                                                      <mat-label>Alarm durch</mat-label>
                                                                      <mat-select formControlName="einsatzdaten_alarmdurch">
                                                                             <mat-option *ngFor="let item of alarmierer ; let i=index" [value]="i">
                                                                                    {{item}}
                                                                             </mat-option>
                                                                      </mat-select>
                                                               </mat-form-field> 
                                                               <mat-form-field fxFlex.gt-sm="25%">
                                                                      <mat-label>Abrechnung über</mat-label>
                                                                      <mat-select formControlName="einsatzdaten_abrechnungueber">
                                                                             <mat-option [value]=0>ILS</mat-option>
                                                                             <mat-option [value]=1>Rechnung</mat-option>
                                                                      </mat-select>
                                                               </mat-form-field> 
                                                        </div>
                                                        <div fxLayout.xs="column" fxLayout="row" >
                                                               <mat-form-field fxFlex.gt-sm="100%">
                                                                      <mat-label>Einsatzmeldung</mat-label>
                                                                      <textarea matInput rows="4" placeholder="bemerkung" formControlName="einsatzdaten_meldung"></textarea>
                                                               </mat-form-field>  
                                                        </div>
                                                        <div fxLayout.xs="column" fxLayout="row" >
                                                               <mat-form-field fxFlex.gt-sm="100%">
                                                                      <mat-label>Einsatzstelle</mat-label>
                                                                      <textarea matInput rows="4" placeholder="bemerkung" formControlName="geodaten_text"></textarea>
                                                               </mat-form-field>  
                                                        </div>
                                                 </form>
                                          </div>
                                   </mat-tab>
                                   <mat-tab label="Einheiten">
                                          <br>
                                          <form [formGroup]="form" style="width: 100%;">
                                                 <div formArrayName="einheiten_array">
                                                        <div *ngFor="let entry of einheitenForm.controls; let i=index" fxLayout.xs="column" [formGroupName]="i" fxLayout="row" >
                                                        <mat-form-field fxFlex.gt-sm="16%">
                                                               <mat-label>Funkrufname</mat-label>
                                                               <mat-select formControlName="einheitKey">
                                                                      <mat-option *ngFor="let item of einheiten | async" [value]="item.payload.doc.id">
                                                                      {{item.payload.doc.data().funkrufname}}
                                                                      </mat-option>
                                                               </mat-select>
                                                        </mat-form-field>
                                                        <mat-form-field fxFlex.gt-sm="16%">
                                                               <mat-label>verantwortliche Einsatzkraft</mat-label>
                                                               <mat-select formControlName="verantwortlicheEinsatzkraftKey">
                                                                      <mat-option *ngFor="let item of einsatzkraefte | async" [value]="item.payload.doc.id">
                                                                      {{item.payload.doc.data().vorname}} {{item.payload.doc.data().name}}
                                                                      </mat-option>
                                                               </mat-select>
                                                        </mat-form-field>
                                                        <mat-form-field fxFlex.gt-sm="16%">
                                                               <mat-label>Beginn</mat-label>
                                                               <input matInput  formControlName="an">
                                                        </mat-form-field>
       
                                                        <mat-form-field fxFlex.gt-sm="16%">
                                                               <mat-label>Ende</mat-label>
                                                               <input matInput  formControlName="ab">
                                                        </mat-form-field>
                                                        <mat-form-field fxFlex.gt-sm="16%">
                                                               <mat-label>Auftragsnummer</mat-label>
                                                               <input matInput  formControlName="auftragsnummer">
                                                        </mat-form-field>
                                                        <button mat-raised-button color="warn" (click)="deleteEinheit(i)">löschen</button>
                                                        </div>
                                                 </div>
                                                 <div align="center">
                                                 <button mat-raised-button color="primary" (click)="addEinheit()">Einheit hinzufügen</button>
                                                 </div>
       
                                          </form>
                                   </mat-tab>
                                   <mat-tab label="Einsatzkräfte">
                                          <br>
                                          <form [formGroup]="form" style="width: 100%;">
                                                 <div formArrayName="einsatzkraefte_array">
                                                        <div *ngFor="let entry of einsatzkraefteForm.controls; let i=index" [formGroupName]="i">
                                                        <mat-form-field style="width: 25%;">
                                                               <mat-label>Name</mat-label>
                                                               <mat-select formControlName="einsatzkraftKey">
                                                                      <mat-option *ngFor="let item of einsatzkraefte | async" [value]="item.payload.doc.id">
                                                                      {{item.payload.doc.data().vorname}} {{item.payload.doc.data().name}}
                                                                      </mat-option>
                                                               </mat-select>
                                                        </mat-form-field>
                                                        <mat-form-field style="width: 20%;">
                                                               <mat-label>Beginn</mat-label>
                                                               <input matInput  formControlName="an">
                                                        </mat-form-field>
       
                                                        <mat-form-field style="width: 20%;">
                                                               <mat-label>Ende</mat-label>
                                                               <input matInput  formControlName="ab">
                                                        </mat-form-field>
                                                        <mat-form-field style="width: 20%;">
                                                               <mat-label>Funktion</mat-label>
                                                               <input matInput  formControlName="funktion">
                                                        </mat-form-field>
                                                        <button mat-raised-button color="warn" (click)="deleteEinsatzkraft(i)">löschen</button>
                                                        </div>
                                                 </div>
                                                 <div align="center">
                                                 <button mat-raised-button color="primary" (click)="addEinsatzkraft()">Einsatzkraft hinzufügen</button>
                                                 </div>
       
                                          </form>
                                   </mat-tab>
                                   <mat-tab label="Einsatzverlauf">
                                          <br>
                                          <form [formGroup]="form" style="width: 100%;">
                                                 <div formArrayName="einsatzverlauf_array">
                                                        <div *ngFor="let entry of einsatzverlaufForm.controls; let i=index" [formGroupName]="i">
                                                        <mat-form-field style="width: 10%;">
                                                               <mat-label>Zeit</mat-label>
                                                               <input matInput  formControlName="timestamp">
                                                        </mat-form-field>
                                                        <mat-form-field style="width: 80%;">
                                                               <mat-label>Angaben zum Einsatzverlauf u. eingesetzte Rettungsmittel</mat-label>
                                                               <input matInput  formControlName="notiz">
                                                        </mat-form-field>
                                                        <button mat-raised-button color="warn" (click)="deleteEinsatzverlauf(i)">löschen</button>
                                                        </div>
                                                 </div>
                                                 <div align="center">
                                                 <button mat-raised-button color="primary" (click)="addEinsatzverlauf()">Eintrag hinzufügen</button>
                                                 </div>
                                                 
                                          </form>
                                   </mat-tab>
                                 </mat-tab-group>
                                 <button mat-raised-button color="primary" (click)="lockEntry()">Abschließen und Protokoll erstellen</button>
                            
                         </div>
                         <div *ngIf="metaStatus == 1">
                            <div style="text-align: center;">
                                   Dieser Einsatz wurde bereits abgeschlossen. 
                                   <br>
                                   <button mat-raised-button color="primary" (click)="einsatzprotokollPdfService.generatePdf();">PDF anzeigen</button>

                                   
                                   
                            </div>
                         </div>


                     
                  </mat-card-content>

           </mat-card>