



<mat-toolbar color="primary">
    <mat-toolbar-row>
      <span class="example-spacer"></span>
      <span >Wachbuch: Details</span>
      <span class="example-spacer"></span>
      
    </mat-toolbar-row>
    
  </mat-toolbar>
  <button mat-fab (click)="showTable.emit();" class="main-fab"><mat-icon>arrow_back_ios</mat-icon></button>


  <mat-card class="formCard">
         <mat-card-content>
                <h2>Details</h2>

<!--New-->

<div fxLayout="column" style="width: 100%;">
       <form [formGroup]="form" style="width: 100%;">
              <div fxLayout.xs="column" fxLayout="row" >
                     <mat-form-field fxFlex.gt-sm="25%">
                            <mat-label>Dienstbeginn</mat-label>
                            <input  matInput  formControlName="dienstbeginn">
                            <mat-error *ngIf="form.get('dienstbeginn').invalid ">Format: 01.12.2020, 19:30</mat-error>
                     </mat-form-field>
                     <mat-form-field fxFlex.gt-sm="25%">
                            <mat-label>Anmeldung RLST</mat-label>
                            <input  matInput  formControlName="anmeldungRLST">
                            <mat-error *ngIf="form.get('anmeldungRLST').invalid ">Format: 01.12.2020, 19:30</mat-error>
                     </mat-form-field>
                     <mat-form-field fxFlex.gt-sm="25%">
                            <mat-label>Abmeldung RLST</mat-label>
                            <input  matInput  formControlName="abmeldungRLST">
                            <mat-error *ngIf="form.get('abmeldungRLST').invalid ">Format: 01.12.2020, 19:30</mat-error>
                     </mat-form-field>
                     <mat-form-field fxFlex.gt-sm="25%">
                            <mat-label>Dienstende</mat-label>
                            <input  matInput formControlName="dienstende">
                            <mat-error *ngIf="form.get('dienstende').invalid ">Format: 01.12.2020, 19:30</mat-error>
                     </mat-form-field>
              </div>
              <div fxLayout.xs="column" fxLayout="row" >
                     <mat-form-field fxFlex.gt-sm="25%">
                            <mat-label>Wachleiter</mat-label>
                            <mat-select formControlName="wachleiter">
                                   <mat-option *ngFor="let item of einsatzkraefte | async" [value]="item.payload.doc.id">
                                   {{item.payload.doc.data().vorname}} {{item.payload.doc.data().name}}
                                   </mat-option>
                            </mat-select>
                     </mat-form-field>
                     <mat-form-field fxFlex.gt-sm="25%">
                            <mat-label>Wetterlage</mat-label>
                            <input matInput  formControlName="wetterlage">
                     </mat-form-field>
                     <mat-form-field fxFlex.gt-sm="25%">
                            <mat-label>Lufttemperatur</mat-label>
                            <input matInput  formControlName="lufttemperatur">
                     </mat-form-field>
                     <mat-form-field >
                            <mat-label>Wassertemperatur</mat-label>
                            <input matInput  formControlName="wassertemperatur">
                     </mat-form-field>
              </div>
              <div fxLayout.xs="column" fxLayout="row" >
                     <mat-form-field fxFlex.gt-sm="100%">
                            <mat-label>Bemerkung</mat-label>
                            <textarea matInput rows="4" placeholder="bemerkung" formControlName="bemerkung"></textarea>
                     </mat-form-field>
              </div>
              <h2>Einsatzkräfte</h2>
              <div formArrayName="einsatzkraefte">
                     <div *ngFor="let entry of einsatzkraefteForm.controls; let i=index" [formGroupName]="i">
                            <div fxLayout.xs="column" fxLayout="row" >
                                   <mat-form-field fxFlex.gt-sm="25%">
                                          <mat-label>Name</mat-label>
                                          <mat-select formControlName="einsatzkraftKey">
                                                 <mat-option *ngFor="let item of einsatzkraefte | async" [value]="item.payload.doc.id">
                                                 {{item.payload.doc.data().vorname}} {{item.payload.doc.data().name}}
                                                 </mat-option>
                                          </mat-select>
                                          <mat-error *ngIf="entry.get('beginn').invalid ">Bitte Einsatzkraft wählen</mat-error>
                                   </mat-form-field>
                                   <mat-form-field  fxFlex.gt-sm="20%">
                                          <mat-label>Beginn</mat-label>
                                          <input matInput formControlName="beginn">
                                          <mat-error *ngIf="entry.get('beginn').invalid ">Format: 01.12.2020, 19:30</mat-error>
                                   </mat-form-field>
                                   <mat-form-field fxFlex.gt-sm="20%">
                                          <mat-label>Ende</mat-label>
                                          <input matInput  formControlName="ende">
                                          <mat-error *ngIf="entry.get('ende').invalid ">Format: 01.12.2020, 19:30</mat-error>
                                   </mat-form-field>
   

                                   <mat-form-field fxFlex.gt-sm="20%">
                                          <mat-label>Funktion</mat-label>
                                          <mat-select formControlName="funktion">
                                                 <mat-option *ngFor="let item of funktionen ; let i=index" [value]="i">
                                                        {{item}}
                                                 </mat-option>
                                          </mat-select>
                                   </mat-form-field>


                                   <button mat-raised-button fxFlex.gt-sm="5%" color="warn" (click)="deleteEinsatzkraft(i)"><mat-icon>delete</mat-icon></button>
                            </div>
                     </div>
              </div>
       </form>
</div>
  <!--Old-->
 

                                
                            

                                
<div align="center">
       <button mat-raised-button color="secondary" (click)="addEinsatzkraft()">Einsatzkraft hinzufügen</button>
</div>

                  </mat-card-content>
           </mat-card>


           
