import { Component, OnInit, ViewChild, Output, EventEmitter, ElementRef} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { EinsatzkraefteService } from 'src/app/services/einsatzkraefte.service';
import { FahrtenService } from 'src/app/services/fahrten.service';
import { EinsatzmittelService } from 'src/app/services/einsatzmittel.service';
import { FahrtenKategorienService } from 'src/app/services/fahrten-kategorien.service';


@Component({
  selector: 'app-fahrtenbuch-table',
  templateUrl: './fahrtenbuch-table.component.html',
  styleUrls: ['./fahrtenbuch-table.component.css']
})
export class FahrtenbuchTableComponent implements OnInit {
  //Observer
  fahrtenObserver;
  einsatzkraefteObserver;
  einsatzmittelObserver;
  editFahrtObserver;
  formChangeObserver;
  //arrays
  einsatzkraefte = {};
  kategorien = [];
  einsatzmittel = {};

  showFilterInputValue = false;

  displayedColumns: string[] = ['einsatzmittel', 'beginn', 'ende', 'kategorie', 'fahrzeugfuehrer','einsatzfahrt', 'star'];
  displayedData

  constructor(
    private einsatzkraefteService: EinsatzkraefteService, 
    private fahrtenService:FahrtenService, 
    private einsatzmittelService:EinsatzmittelService,
    private kategorienService:FahrtenKategorienService
  ) {
    this.displayedData = this.fahrtenService.getJoinedEntries()
    this.kategorien = this.kategorienService.getEntries()
    
  }

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild("filterField", {static: true}) filterField: ElementRef;

  

  @Output() editEntry = new EventEmitter<string>();


  
  ngOnInit() {
    this.initSort()
  }

  initSort(){
    this.displayedData.sort = this.sort;
    this.displayedData.paginator = this.paginator;
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.displayedData.filter = filterValue.trim().toLowerCase();
  }

  newEntry() {
    let data = {
      beginn: '',
      ende: '',
      einsatzmittel: '',
      fahrzeugfuehrer: '',
      besatzung: [],
      bemerkung: '',
      alarmfahrt: '',
      kategorie: '',
      zweck: '',
      kilometerstand_von: '',
      kilometerstand_bis: '',
      betriebsstunden_von: '',
      betriebsstunden_bis: '',
      organization: 'ZRjT3vKRLSBJSBNVGZx0',
    }
    this.fahrtenService.createEntry(data)
    
    

    
    
    //this.editEntry.emit(key)
  }
  deleteEntry(key){
    this.fahrtenService.deleteEntry(key);
  }
  showFilterInput(){
    this.showFilterInputValue = true;
    this.filterField.nativeElement.focus()
  }
  closeFilterInput(){
    this.showFilterInputValue = false;
  }
}
