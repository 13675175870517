import { Injectable } from '@angular/core';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Injectable({
  providedIn: 'root'
})
export class EinsatzprotokollPDFService {

  constructor() { }

  generatePdf(){
    const documentDefinition = { content: 'This is for testing.' };
    pdfMake.createPdf(documentDefinition).open();
  }
}
