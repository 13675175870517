
import { Component, OnInit, } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';




@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  form: FormGroup = this.formBuildService.group({
    email: '',
    password1: '',
    password2: '',
  })

  constructor(
    public auth: AuthService,
    private formBuildService: FormBuilder,
  ) { }

  async register() {
    var username = this.form.get("email").value;
    var password1 = this.form.get("password1").value;
    var password2 = this.form.get("password2").value;    
    if (password1 == password2) {      
      await this.auth.register(username, password1)
    }
  }
  

  ngOnInit() {

        
  }









}
