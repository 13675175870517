<mat-toolbar color="primary">
    <mat-toolbar-row>
      <span class="example-spacer"></span>
      <mat-form-field style="width: 90%;" *ngIf="showFilterInputValue" >
        <input #filterField matInput (keyup)="applyFilter($event)" (blur)="closeFilterInput()" placeholder="Suchbegriff eingeben" >
      </mat-form-field>
      <span *ngIf="!showFilterInputValue">Einsätze</span>
      <span class="example-spacer"></span>
      <mat-icon style="cursor: pointer;" *ngIf="!showFilterInputValue" (click)="showFilterInput()">search</mat-icon>
    </mat-toolbar-row>
    
  </mat-toolbar>
   
  
  
  
    <button mat-fab (click)="newEntry()" class="main-fab"><mat-icon>add</mat-icon></button>
  <div style="height: 100; width: 100;">
   <table mat-table matSort [dataSource]="displayedData" fxHide="false" fxHide.xs="true">
      <ng-container matColumnDef="beginn">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Beginn </th>
          <td mat-cell *matCellDef="let element">{{ element.einsatzdaten_alarmzeit.seconds * 1000 | date:'dd.MM.yyyy hh:mm' }}</td>
        </ng-container>
      <ng-container matColumnDef="ende">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Ende </th>
          <td mat-cell *matCellDef="let element">{{ element.einsatzdaten_endezeit.seconds * 1000 | date:'dd.MM.yyyy hh:mm' }}</td>
      </ng-container>
      <ng-container matColumnDef="location">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Einsatzort </th>
          <td mat-cell *matCellDef="let element"> {{element.geodaten_text}} </td>
      </ng-container>
      <ng-container matColumnDef="star" stickyEnd>
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button (click)="editEntry.emit(element.id);">
              <mat-icon>create</mat-icon>
            </button>
            <button mat-icon-button (click)="deleteEntry(element.id);">
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </ng-container> 
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>  
    </table>
    <mat-list fxHide="true" fxHide.xs="false">
      <mat-list-item *ngFor="let item of displayedData | async" (click)="editEntry.emit(item.id);">{{ item.einsatzdaten_alarmzeit.seconds * 1000 | date:'dd.MM.yyyy hh:mm' }},  {{item.geodaten_text}}</mat-list-item>

    </mat-list>
    
  </div>
  
  
  