import { Component } from '@angular/core';


@Component({
  selector: 'app-fahrtenbuch',
  templateUrl: './fahrtenbuch.component.html',
  styleUrls: ['./fahrtenbuch.component.css']
})

export class FahrtenbuchComponent {
  showTableStatus;
  showFormStatus;
  formEntryId;
  constructor() {
    this.showTableStatus=true;
    this.showFormStatus=false;
  }


  editEntry(key) {
    this.formEntryId = key;
    this.showTableStatus=false;
    this.showFormStatus=true;
  }
  showTable(){
    this.formEntryId = '';
    this.showTableStatus=true;
    this.showFormStatus=false;
  }


  
}

