import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FahrtenbuchComponent } from './components/fahrtenbuch/fahrtenbuch.component';
import { LoginComponent } from './components/login/login.component';


import { RegisterComponent } from './components/register/register.component';

import { LagekarteComponent } from './components/lagekarte/lagekarte.component';
import { WachbuchComponent } from './components/wachbuch/wachbuch.component';
import { EinsaetzeComponent } from './components/einsaetze/einsaetze.component';
import { EinsaetzeFormComponent } from './components/einsaetze-form/einsaetze-form.component';
import { OrganisationSelectorComponent } from './components/organisation-selector/organisation-selector.component';
import { AuthGuard } from './auth.guard';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { AdminComponent } from './components/admin/admin.component';
import { ProfileComponent } from './components/profile/profile.component';
import { OrtungComponent } from './components/ortung/ortung.component';




const routes: Routes = [
  { path: 'fahrtenbuch', component: FahrtenbuchComponent, canActivate: [AuthGuard],  data: {roles: ['fahrtenbuch', ]} },
  { path:  'login',component:  LoginComponent},
  { path:  'register', component:  RegisterComponent },
  { path:  'reset-password', component:  ResetPasswordComponent },
  { path:  'map', component:  LagekarteComponent, canActivate: [AuthGuard],  data: {roles: ['map', ]}  },
  { path:  'wachbuch', component:  WachbuchComponent, canActivate: [AuthGuard] ,  data: {roles: ['wachbuch', ]} },
  { path:  'einsaetze', component:  EinsaetzeComponent, canActivate: [AuthGuard] ,  data: {roles: ['einsaetze', ]} },
  { path:  'admin', component:  AdminComponent, canActivate: [AuthGuard] ,  data: {roles: ['admin', ]} },
  { path:  'organisations', component:  OrganisationSelectorComponent, canActivate: [AuthGuard],  data: {roles: ['organisations', ]} },
  { path:  'profile', component:  ProfileComponent, canActivate: [AuthGuard],  data: {roles: ['profile', ]} },
  { path:  'ortung', component:  OrtungComponent, canActivate: [AuthGuard],  data: {roles: ['ortung', ]} },
  { path:  '', redirectTo: 'organisations', pathMatch: 'full' },
  { path:  '**', redirectTo: 'organisations' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
