import { Component, OnInit } from '@angular/core';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {


  public loginInvalid: boolean;
  private formSubmitAttempt: boolean;
  private returnUrl: string;

  form: FormGroup = this.formBuildService.group({
    email: '',
    password: '',
  })

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    public auth: AuthService,
    private formBuildService: FormBuilder,
  ) { }

  async ngOnInit() {
/*
    this.form = this.fb.group({
      username: ['', Validators.email],
      password: ['', Validators.required]
    });

    if (await this.authService.checkAuthenticated()) {
      await this.router.navigate([this.returnUrl]);
    }
*/
    
  }

  async emailLogin() {
    var username = this.form.get("email").value;
    var password = this.form.get("password").value;
    await this.auth.emailSignin(username, password);
  }
/*
  async onSubmit() {
    this.loginInvalid = false;
    this.formSubmitAttempt = false;
    if (this.form.valid) {
      try {
        const username = this.form.get('username').value;
        const password = this.form.get('password').value;        
        
      } catch (err) {
        this.loginInvalid = true;
        console.log("error");
        
      }
    } else {
      this.formSubmitAttempt = true;
      console.log("it works");
      
    }
  }
*/

}
