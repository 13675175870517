import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-einsaetze',
  templateUrl: './einsaetze.component.html',
  styleUrls: ['./einsaetze.component.css']
})
export class EinsaetzeComponent implements OnInit {

  showTableStatus;
  showFormStatus;
  formEntryId;

  constructor() {
    this.showTableStatus=true;
    this.showFormStatus=false;
  }

  ngOnInit() {
  }

  editEntry(key) {
    this.formEntryId = key;
    this.showTableStatus=false;
    this.showFormStatus=true;
  }
  showTable(){
    this.formEntryId = '';
    this.showTableStatus=true;
    this.showFormStatus=false;
  }

}
