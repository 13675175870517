import { Component, OnInit, Input, Output, EventEmitter, Pipe, PipeTransform } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { EinsatzkraefteService } from 'src/app/services/einsatzkraefte.service';
import { WachdiensteService } from 'src/app/services/wachdienste.service';
import { DatePipe } from '@angular/common';
import { EinsatzkraefteFunktionenService } from 'src/app/services/einsatzkraefte-funktionen.service';
import { EinsatzmittelService } from 'src/app/services/einsatzmittel.service';
import { Subscription } from 'rxjs';
import { EinsaetzeService } from 'src/app/services/einsaetze.service';
import * as moment from 'moment';
import { EinsaetzeEinsatzartenService } from 'src/app/services/einsaetze-einsatzarten.service';
import { EinsaetzeAlarmiererService } from 'src/app/services/einsaetze-alarmierer.service';
import { AngularFireStorage } from 'angularfire2/storage';
import { take } from 'rxjs/operators';
import { EinsatzprotokollPDFService } from 'src/app/services/einsatzprotokoll-pdf.service';




@Component({
  selector: 'app-einsaetze-form',
  templateUrl: './einsaetze-form.component.html',
  styleUrls: ['./einsaetze-form.component.css']
})
export class EinsaetzeFormComponent implements OnInit {

   //Observer
   einsatzkraefte
   einheiten
   funktionen
   einsatzarten
   alarmierer
   
  //handle pdf
  pdfURL;
  metaStatus: number;
  pauseForm: boolean = false;



   einsatzSubscription: Subscription
   formSubscription: Subscription


   form: FormGroup = this.formBuildService.group({
    einsatzdaten_einsatzart: '',
    einsatzdaten_alarmzeit: ['', [
      Validators.required,
      Validators.pattern('^(0[1-9]|[1-2][0-9]|3[0-1])\.(0[1-9]|1[0-2])\.[0-9]{4}\, (0[1-9]|1[0-9])\:([0-6][0-9])$'),
    ]],
    einsatzdaten_endezeit: ['', [
      Validators.pattern('^(0[1-9]|[1-2][0-9]|3[0-1])\.(0[1-9]|1[0-2])\.[0-9]{4}\, (0[1-9]|1[0-9])\:([0-6][0-9])$'),
    ]],
    einsatzdaten_alarmdurch: '',
    einsatzdaten_meldung: '',
    geodaten_text: '',
    einsatzdaten_abrechnungueber: '',
    gefaehrdungsbeurteilung: '',
    einheiten_array: this.formBuildService.array([]),
    einsatzkraefte_array: this.formBuildService.array([]),
    einsatzverlauf_array: this.formBuildService.array([]),
    meta_status: "",
  },{ updateOn: 'blur' }
  )
  @Input() entryId: string;
  @Output() showTable = new EventEmitter<any>();
  //Einheiten
  get einheitenForm() {
    return this.form.get('einheiten_array') as FormArray
  }
  addEinheit() {
    const entry = this.formBuildService.group({
      einheitKey: [],
      verantwortlicheEinsatzkraftKey: [],
      an: ['', [
        Validators.pattern('^(0[1-9]|[1-2][0-9]|3[0-1])\.(0[1-9]|1[0-2])\.[0-9]{4}\, (0[1-9]|1[0-9])\:([0-6][0-9])$'),
      ]],
      ab: ['', [
        Validators.pattern('^(0[1-9]|[1-2][0-9]|3[0-1])\.(0[1-9]|1[0-2])\.[0-9]{4}\, (0[1-9]|1[0-9])\:([0-6][0-9])$'),
      ]],
      auftragsnummer: []
    })
    this.einheitenForm.push(entry)
  }
  deleteEinheit(i) {
    this.einheitenForm.removeAt(i)
  }
  //Einsatzkräfte
  get einsatzkraefteForm() {
    return this.form.get('einsatzkraefte_array') as FormArray
  }
  addEinsatzkraft() {
    const entry = this.formBuildService.group({
      einsatzkraftKey: [],
      an: ['', [
        Validators.pattern('^(0[1-9]|[1-2][0-9]|3[0-1])\.(0[1-9]|1[0-2])\.[0-9]{4}\, (0[1-9]|1[0-9])\:([0-6][0-9])$'),
      ]],
      ab: ['', [
        Validators.pattern('^(0[1-9]|[1-2][0-9]|3[0-1])\.(0[1-9]|1[0-2])\.[0-9]{4}\, (0[1-9]|1[0-9])\:([0-6][0-9])$'),
      ]],
      funktion: []
    })
    this.einsatzkraefteForm.push(entry)
  }
  deleteEinsatzkraft(i) {
    this.einheitenForm.removeAt(i)
  }
  //Einsatzverlauf
  get einsatzverlaufForm() {
    return this.form.get('einsatzverlauf_array') as FormArray
  }
  addEinsatzverlauf() {
    const entry = this.formBuildService.group({
      timestamp: ['', [
        Validators.pattern('^(0[1-9]|[1-2][0-9]|3[0-1])\.(0[1-9]|1[0-2])\.[0-9]{4}\, (0[1-9]|1[0-9])\:([0-6][0-9])$'),
      ]],
      notiz: [],
    })
    this.einsatzverlaufForm.push(entry)
  }
  deleteEinsatzverlauf(i) {
    this.einsatzverlaufForm.removeAt(i)
  }

  constructor(
    private einsatzkraefteService: EinsatzkraefteService, 
    private einheitenService:EinsatzmittelService,
    private funktionenService:EinsatzkraefteFunktionenService,
    private einsaetzeService:EinsaetzeService,
    private einsatzartenService:EinsaetzeEinsatzartenService,
    private alarmiererService:EinsaetzeAlarmiererService,
    private storageService: AngularFireStorage,
    private formBuildService: FormBuilder,
    public datepipe: DatePipe,
    public einsatzprotokollPdfService: EinsatzprotokollPDFService,
  ) { 
    
  }

  ngOnInit() {
    
    

    this.einsatzkraefte = this.einsatzkraefteService.getEntries()
    this.funktionen     = this.funktionenService.getEntries()
    this.einheiten      = this.einheitenService.getEntries()
    this.einsatzarten   = this.einsatzartenService.getEntries()
    this.alarmierer     = this.alarmiererService.getEntries()

    this.einsatzSubscription = this.einsaetzeService.getSingleEntry(this.entryId).subscribe((element: any) => {
      var formData;
      formData = element.payload.data() 
      this.metaStatus = formData.meta_status
      if (formData.meta_status == 2) {
        this.storageService.ref("einsatzprotokolle/"+this.entryId+".pdf").getDownloadURL().pipe(take(1)).subscribe((url:string) => {
          this.pdfURL = url;
        })
      }
      if (formData.einsatzdaten_alarmzeit != "") {
        const date = new Date(formData.einsatzdaten_alarmzeit.seconds*1000);
        formData.einsatzdaten_alarmzeit = moment(date).format('DD.MM.YYYY, HH:mm');    
      }
      if (formData.einsatzdaten_endezeit != "") {
        const date = new Date(formData.einsatzdaten_endezeit.seconds*1000);
        formData.einsatzdaten_endezeit = moment(date).format('DD.MM.YYYY, HH:mm');  
      }
      this.pauseForm = true;
      this.form.patchValue(formData, {emitEvent: false}) 
      //Handle Einheiten
      this.einheitenForm.clear()
      formData.einheiten_array.forEach((element: any) => {
        if (element.an != "") {
          const date = new Date(element.an.seconds*1000);
          element.an = moment(date).format('DD.MM.YYYY, HH:mm');  
        }
        if (element.ab != "") {
          const date = new Date(element.ab.seconds*1000);
          element.ab = moment(date).format('DD.MM.YYYY, HH:mm');  
        }
        var entry = this.formBuildService.group({
          einheitKey: element.einheitKey,
          an: element.an,
          ab: element.ab,
          verantwortlicheEinsatzkraftKey: element.verantwortlicheEinsatzkraftKey,
          auftragsnummer: element.auftragsnummer
        })
        this.einheitenForm.push(entry) 
      });
      //Handle Einsatzkraefte
      this.einsatzkraefteForm.clear()
      formData.einsatzkraefte_array.forEach((element: any) => {
        if (element.an != "") {
          const date = new Date(element.an.seconds*1000);
          element.an = moment(date).format('DD.MM.YYYY, HH:mm');  
        }
        if (element.ab != "") {
          const date = new Date(element.ab.seconds*1000);
          element.ab = moment(date).format('DD.MM.YYYY, HH:mm');  
        }
        var entry = this.formBuildService.group({
          einsatzkraftKey: element.einsatzkraftKey,
          an: element.an,
          ab: element.ab,
          funktion: element.funktion
        })
        this.einsatzkraefteForm.push(entry) 
      });
      //Handle Einsatzverlauf
      this.einsatzverlaufForm.clear()
      formData.einsatzverlauf_array.forEach((element: any) => {
        if (element.timestamp != "") {
          const date = new Date(element.timestamp.seconds*1000);
          element.timestamp = moment(date).format('DD.MM.YYYY, HH:mm');  
        }
        var entry = this.formBuildService.group({
          timestamp: element.timestamp,
          notiz: element.notiz,
        })
        this.einsatzverlaufForm.push(entry) 
      });
      this.pauseForm = false;
    })
    this.formSubscription =  this.form.valueChanges.subscribe(data => {
      if (this.pauseForm == false && this.form.valid) {
        console.log("save");
        this.saveChanges();
      } else {
        console.log("not save");
        
      }
    })
    


  }
  saveChanges() {
    const data = this.form.value
    console.log(data);
    
    if (data.einsatzdaten_alarmzeit == "") {
      data.einsatzdaten_alarmzeit = ""
    } else {
      var dateString = data.einsatzdaten_alarmzeit
      const momentVariable = moment(dateString, 'DD.MM.YYYY, HH:mm');
      data.einsatzdaten_alarmzeit = momentVariable.toDate()
    }
    if (data.einsatzdaten_endezeit == "") {
      data.einsatzdaten_endezeit = ""
    } else {
      var dateString = data.einsatzdaten_endezeit
      const momentVariable = moment(dateString, 'DD.MM.YYYY, HH:mm');
      data.einsatzdaten_endezeit = momentVariable.toDate()
    }
    //Handle Einheiten
    data.einheiten_array = data.einheiten_array.map(element =>{
      var newEntry = element     
      if (newEntry.an == "") {
        newEntry.an = ""
      } else {
        var dateString = newEntry.an
        const momentVariable = moment(dateString, 'DD.MM.YYYY, HH:mm');
        newEntry.an = momentVariable.toDate()
      }
      if (newEntry.ab == "") {
        newEntry.ab = ""
      } else {
        var dateString = newEntry.ab
        const momentVariable = moment(dateString, 'DD.MM.YYYY, HH:mm');
        newEntry.ab = momentVariable.toDate()
      }
      return newEntry
    })
    //Handle Einsatzkraefte
    data.einsatzkraefte_array = data.einsatzkraefte_array.map(element =>{
      var newEntry = element     
      if (newEntry.an == "") {
        newEntry.an = ""
      } else {
        var dateString = newEntry.an
        const momentVariable = moment(dateString, 'DD.MM.YYYY, HH:mm');
        newEntry.an = momentVariable.toDate()
      }
      if (newEntry.ab == "") {
        newEntry.ab = ""
      } else {
        var dateString = newEntry.ab
        const momentVariable = moment(dateString, 'DD.MM.YYYY, HH:mm');
        newEntry.ab = momentVariable.toDate()
      }
      return newEntry
    })
    //Handle Einsatzverlauf
    data.einsatzverlauf_array = data.einsatzverlauf_array.map(element =>{
      var newEntry = element     
      if (newEntry.timestamp == "") {
        newEntry.timestamp = ""
      } else {
        var dateString = newEntry.timestamp
        const momentVariable = moment(dateString, 'DD.MM.YYYY, HH:mm');
        newEntry.timestamp = momentVariable.toDate()
      }
      
      return newEntry
    })
    this.einsaetzeService.updateEntry(this.entryId, data)
  }
  lockEntry() {
    this.einsaetzeService.lockEntry(this.entryId);
    this.einsatzprotokollPdfService.generatePdf();
  }
  ngOnDestroy() {
    this.einsatzSubscription.unsubscribe()
    this.formSubscription.unsubscribe()
  }

}
