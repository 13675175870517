import { Injectable } from '@angular/core';
import { AngularFirestore } from 'angularfire2/firestore';
import * as firebase from 'firebase';

@Injectable({
  providedIn: 'root'
})
export class OrtungService {

  constructor(private firestore: AngularFirestore) { }
  getWaypointsForEinheitAndDateTimeRange(einheit, beginn, ende){ 
    //, ref => ref.where('organisationKey', '==', localStorage.getItem("currentOrganisation"))
   // return this.firestore.collection('ortung', ref => ref.where('einheit', '==', einheit).where('timestamp', '>=', beginn).where('timestamp', '<=', ende)).snapshotChanges()
  }
  getLastWaypointforEinheit(einheit) {
    //, ref => ref)
   return this.firestore.collection('ortung', ref => ref
    .where('organisationKey', '==', localStorage.getItem("currentOrganisation"))
    .where('einheit', '==', einheit)
    .orderBy("timestamp", "desc").limit(1)
  ).snapshotChanges()
  }
  setNewWaypoint(einheitKey, lat, lon, timestamp) {
    const datetime = new Date(timestamp);
    const organisationKey = localStorage.getItem("currentOrganisation");
    const geopoint = new firebase.firestore.GeoPoint(lat, lon);
    return this.firestore.collection('ortung').add({
      organisationKey: organisationKey,
      einheit: einheitKey,
      timestamp: datetime,
      position: geopoint,
    })
  }
}
