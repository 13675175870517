import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  constructor(
    public auth: AuthService,
    private formBuildService: FormBuilder,
  ) { }

  form: FormGroup = this.formBuildService.group({
    email: '',
  })

  ngOnInit(): void {
  }
  async reset() {
    var email = this.form.get("email").value;
    await this.auth.sendPasswordResetEmail(email);
  }

}
