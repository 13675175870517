import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FahrtenKategorienService {

  constructor() { }
  getEntries(){
    return ["Betankung", "Fahrt", "Wartung", "Schadensmeldung"];
  }
}
