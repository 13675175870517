<app-navigation>
    <mat-toolbar color="primary">
        <mat-toolbar-row>
          <span class="example-spacer"></span>
          <span >Ortung</span>
          <span class="example-spacer"></span>
        </mat-toolbar-row>
      </mat-toolbar>


    <mat-card class="formCard">
        <mat-card-content>
            <form [formGroup]="form" style="width: 100%;">
                <mat-form-field style="width: 100%;">
                    <mat-label>Einheit auswählen</mat-label>
                    <mat-select formControlName="selectedEinheit">
                            <mat-option *ngFor="let item of einheiten | async" [value]="item.payload.doc.id">
                            {{item.payload.doc.data().funkrufname}}
                            </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field style="width: 100%;">
                    <mat-label>Lat</mat-label>
                    <input matInput  formControlName="lat">
                </mat-form-field>
                <mat-form-field style="width: 100%;">
                    <mat-label>Lon</mat-label>
                    <input matInput  formControlName="lon">
                </mat-form-field>
                <mat-form-field style="width: 100%;">
                    <mat-label>Timestamp</mat-label>
                    <input matInput  formControlName="timestamp">
                </mat-form-field>
            </form>
            <button mat-raised-button *ngIf="!trackingActivated && form.valid" color="primary" (click)="startTracking()">Ortung starten</button>
            <button mat-raised-button *ngIf="trackingActivated" color="primary" (click)="stopTracking()">Ortung beenden</button>
            <br>
        </mat-card-content>
    </mat-card>


</app-navigation>