
import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { EinsatzmittelService } from 'src/app/services/einsatzmittel.service';
import { EinsatzkraefteService } from 'src/app/services/einsatzkraefte.service';
import { FahrtenService } from 'src/app/services/fahrten.service';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common'
//import { FahrtenbuchMapComponent } from '../fahrtenbuch-map/fahrtenbuch-map.component';
import { FahrtenKategorienService } from 'src/app/services/fahrten-kategorien.service';
import { OnDestroy } from "@angular/core";
import { Subscription } from 'rxjs';
import * as moment from 'moment';




@Component({
  selector: 'app-fahrtenbuch-form',
  templateUrl: './fahrtenbuch-form.component.html',
  styleUrls: ['./fahrtenbuch-form.component.css']
})
export class FahrtenbuchFormComponent implements OnInit {


  kategorien
  kategorie
  einsatzkraefte
  einsatzmittel



  fahrt
  fahrtenSubscription: Subscription
  formSubscription: Subscription





  pauseForm: boolean = false;


  form: FormGroup = this.formBuildService.group({
    beginn: ['', [
      Validators.required,
      Validators.pattern('^(0[1-9]|[1-2][0-9]|3[0-1])\.(0[1-9]|1[0-2])\.[0-9]{4}\, (0[1-9]|1[0-9])\:([0-6][0-9])$'),
    ]],
    ende: ['', [
      Validators.pattern('^(0[1-9]|[1-2][0-9]|3[0-1])\.(0[1-9]|1[0-2])\.[0-9]{4}\, (0[1-9]|1[0-9])\:([0-6][0-9])$'),
    ]],
    einsatzmittel: '',
    fahrzeugfuehrer: '',
    bemerkung: '',
    alarmfahrt: '',
    kategorie: '',
    zweck: '',
    kilometerstand_von: '',
    kilometerstand_bis: '',
    betriebsstunden_von: '',
    betriebsstunden_bis: '',
    besatzung: this.formBuildService.array([]),
    mengeKraftstoff: '',
    mengeOel: '',
    benachrichtigungVerantwortlicher: '',

  },{ updateOn: 'blur' }
  )




  @Input() entryId: string;
  @Output() showTable = new EventEmitter<any>();

 // @ViewChild(FahrtenbuchMapComponent, {static: false})  mapComponent: FahrtenbuchMapComponent;
  
  constructor(
    private einsatzkraefteService: EinsatzkraefteService, 
    private fahrtenService:FahrtenService, 
    private einsatzmittelService:EinsatzmittelService,
    private kategorienService:FahrtenKategorienService,
    private formBuildService: FormBuilder,
    public datepipe: DatePipe
  ) {  }

  ngOnInit() {
    this.einsatzkraefte = this.einsatzkraefteService.getEntries()
    this.kategorien = this.kategorienService.getEntries()
    this.einsatzmittel = this.einsatzmittelService.getEntries()

    this.fahrtenSubscription = this.fahrtenService.getSingleEntry(this.entryId).subscribe((element: any) => {
      if (element.beginn.seconds != null) {
         const date = new Date(element.beginn.seconds*1000);
         element.beginn = moment(date).format('DD.MM.YYYY, HH:mm');
      }
      if (element.ende.seconds != null) {
        const date = new Date(element.ende.seconds*1000);
        element.ende = moment(date).format('DD.MM.YYYY, HH:mm');
      }
      this.kategorie = element.kategorie
      this.pauseForm = true;
      this.form.patchValue(element)
      this.besatzungForm.clear()
      element.besatzung.forEach(element => {
        const entry = this.formBuildService.group({
          einsatzkraftKey: element.einsatzkraftKey
        })
        this.besatzungForm.push(entry)        
      });
      this.pauseForm = false;





    })


    this.formSubscription =  this.form.valueChanges.subscribe(data => {
      if (this.pauseForm == false && this.form.valid) {
        console.log("save");
        this.saveChanges();
      } else {
        console.log("not save");
        
      }
    })
      
    
  }
   
  
  saveChanges() {
    const data = this.form.value
    if (data.beginn == "") {
      data.beginn = ""
    } else {
      var dateString = data.beginn
      const momentVariable = moment(dateString, 'DD.MM.YYYY, HH:mm');
      data.beginn = momentVariable.toDate()
    } 
    if (data.ende == "") {
      data.ende = ""
    } else {
      var dateString = data.ende
      const momentVariable = moment(dateString, 'DD.MM.YYYY, HH:mm');
      data.ende = momentVariable.toDate()
    }
    console.log(data);
    this.fahrtenService.updateEntry(this.entryId, data)
  }


  get besatzungForm() {   
    return this.form.get('besatzung') as FormArray
  }
  addEinsatzkraft() {
    const entry = this.formBuildService.group({
      einsatzkraftKey: []
    })
    this.besatzungForm.push(entry)
  }
  deleteEinsatzkraft(i) {
    this.besatzungForm.removeAt(i)

  }


    

    
  





  ngOnDestroy() {
    this.fahrtenSubscription.unsubscribe()
    this.formSubscription.unsubscribe()
  }



}

