import { Component, OnInit, Input } from '@angular/core';
import * as L from 'leaflet';
import { OrtungService } from 'src/app/services/ortung.service';
import { FahrtenService } from 'src/app/services/fahrten.service';


@Component({
  selector: 'app-fahrtenbuch-map',
  templateUrl: './fahrtenbuch-map.component.html',
  styleUrls: ['./fahrtenbuch-map.component.css']
})
export class FahrtenbuchMapComponent implements OnInit {
  map;
  waypointsObservable;
  polyline;
  fahrten;

  beginn
  ende
  einheitKey


  tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
   maxZoom: 19,
   attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
  });

  @Input() fahrtenbuchEntryKey: string;


  constructor(
    private ortungService:OrtungService,
    private fahrtenService:FahrtenService, 
    
  ) {
    

    
      

  }

  ngOnInit() {
    //this.loadFahrtenData();
    
    
  
  }
  ngAfterViewInit(): void {
    //console.log(this.einheit);
    //console.log(this.beginn + " - " + this.ende);
    //this.beginn = new Date(1569170000000);
    //this.ende = new Date(1569171724000)
    
    this.polyline = L.polyline([], { color: 'red', weight: 3 })

    //get Beginn, Ende, EinheitID
    this.loadFahrtenData();
    
    
    
    this.initMap()
    



    

  }
  private initMap(): void {
    this.map = L.map('fahrtenbuchMap', {
      zoomControl: false,
    }).setView([48.0152, 11.0769], 13);
    
   
    this.tiles.addTo(this.map);

    this.polyline.addTo(this.map);   
    
  }
  loadFahrtenData() {
    this.fahrtenService.getSingleEntry(this.fahrtenbuchEntryKey).subscribe(element => {
      //this.beginn     = new Date(element.payload.data().beginn.seconds*1000);
      //this.ende       = new Date(element.payload.data().ende.seconds*1000);
      //this.einheitKey = element.payload.data().einsatzmittel
      this.loadOrtungData()
    })
    
  }
  loadOrtungData() {   
    /*
    this.ortungService.getWaypointsForEinheitAndDateTimeRange(this.einheitKey,this.beginn,this.ende).subscribe((result:any[])=>{      
      result.forEach(element => {
        let lat = element.payload.doc.data().position.O
        let lon = element.payload.doc.data().position.F
        this.polyline.addLatLng([lat, lon]);
        
      });

      this.map.fitBounds(this.polyline.getBounds()); 
   });
   */
  }

}
