import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';


import { Observable } from 'rxjs';
import { tap, map, take } from 'rxjs/operators';
import { AuthService } from './services/auth.service';

@Injectable()
export class AuthGuard implements CanActivate{
  constructor(private auth: AuthService, private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    let roles = next.data.roles as Array<string>;
    
    
    return this.auth.user$.pipe(
         take(1),
         map(user => !!user), // <-- map to boolean
         tap(loggedIn => {
           if (!loggedIn) {
             console.log('not logged in')
             this.router.navigate(['login']);
           } else {
            switch (roles[0]) {
              case "organisations":
                break;
              case "profile":
                break;
              default:
                var organisationKey = localStorage.getItem("currentOrganisation");
                if (!organisationKey) {
                  console.log('no organisation selected')
                  this.router.navigate(['organisations']);
                } else {
                  this.auth.user$.pipe(take(1)).subscribe((data: any) => {
                    var userGroups = [];                
                    data.organisations.forEach(element => {

                      
                      
                      if (element.organisationKey == organisationKey) {

                        
                        userGroups = element.groups
                      }
                    });
            

                    
                    
                    
                    if (!userGroups.includes(roles[0])) {
                      console.log('no permission for module')
                      this.router.navigate(['organisations']);
                    }
                  })
                }
                break;
            }




           }
       })
  )
}

}
