import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { EinsaetzeService } from 'src/app/services/einsaetze.service';

@Component({
  selector: 'app-einsaetze-table',
  templateUrl: './einsaetze-table.component.html',
  styleUrls: ['./einsaetze-table.component.css']
})
export class EinsaetzeTableComponent implements OnInit {

  displayedColumns: string[] = ['beginn', 'ende', 'location', 'star'];
  displayedData
  showFilterInputValue

  constructor(private einsaetzeService: EinsaetzeService) {
    
  }

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild("filterField", {static: true}) filterField: ElementRef;

  @Output() editEntry = new EventEmitter<string>();

  ngOnInit() {
    this.displayedData = this.einsaetzeService.getEntries()
  }
/*
  setTableData() {

     
    
    //Subscribe to data for Table
    
    this.einsaetzeService.getEntries().subscribe(data => {
      let newTableData =[]
      data.forEach(element => {

        let elementData           = element.payload.doc.data();
        console.log(elementData);
        let beginnValue           = elementData.einsatzdaten_alarmzeit;
        let endeValue             = elementData.einsatzdaten_endezeit;
        let key                   = element.payload.doc.id;
        let geodaten_text         = elementData.geodaten_text;
        let newEntry = {beginn: beginnValue, ende: endeValue, key: key, location: geodaten_text}
        newTableData.push(newEntry)
      });
      this.displayedData = new MatTableDataSource(newTableData); 
      this.initSort();
      
      //console.log(newTableData);
    })

    
  }
  */
  initSort(){
    this.displayedData.sort = this.sort;
    this.displayedData.paginator = this.paginator;
  }
  newEntry() {
    let data = {
      einsatzdaten_einsatzart: '',
      einsatzdaten_alarmzeit: '',
      einsatzdaten_endezeit: '',
      einsatzdaten_alarmdurch: '',
      einsatzdaten_meldung: '',
      geodaten_text: '',
      einsatzdaten_abrechnungueber: '',
      gefaehrdungsbeurteilung: '',
      einheiten_array: [],
      einsatzkraefte_array: [],
      einsatzverlauf_array: [],
      meta_status: 0,
    }
    let key = this.einsaetzeService.createEntry(data)
  }
  deleteEntry(key){
    this.einsaetzeService.deleteEntry(key);
  }
  showFilterInput(){
    this.showFilterInputValue = true;
    this.filterField.nativeElement.focus()
  }
  closeFilterInput(){
    this.showFilterInputValue = false;
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.displayedData.filter = filterValue.trim().toLowerCase();
  }

}
