import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Subscription } from 'rxjs';
import { OrganisationsService } from 'src/app/services/organisations.service';
import { take } from 'rxjs/operators';
import { AngularFireStorage } from 'angularfire2/storage';
import { Router } from '@angular/router';

@Component({
  selector: 'app-organisation-selector',
  templateUrl: './organisation-selector.component.html',
  styleUrls: ['./organisation-selector.component.css']
})
export class OrganisationSelectorComponent implements OnInit {

  userSubscription: Subscription;
  organisationsData = [];

  
  constructor(
    private router: Router,
    public auth: AuthService,
    private organisationsService: OrganisationsService,
    private storageService: AngularFireStorage,
  ) { }

  ngOnInit() {

    
    this.auth.user$.subscribe(data => {     
      this.organisationsData = [];
      data.organisations?.forEach(element => {
        this.organisationsService.getSingleEntry(element.organisationKey).pipe(take(1)).subscribe(result => {
          var newEntry = result.payload.data()
          newEntry["organisationKey"] = element.organisationKey;
          this.storageService.ref("organisation_logos/"+element.organisationKey+".png").getDownloadURL().pipe(take(1)).subscribe(url => {
            newEntry["logo"] = url;
          })
          this.storageService.ref("organisation_header/"+element.organisationKey+".png").getDownloadURL().pipe(take(1)).subscribe(url => {
            newEntry["header"] = url;
          })
          this.organisationsData.push(newEntry);
        })
      });
    })
  }

  ngOnDestroy(){
    //this.userSubscription.unsubscribe();
  }

  setCurrentOrganisation(key) {
    localStorage.setItem("currentOrganisation", key);
    this.router.navigate(['wachbuch']); 
  }

}
