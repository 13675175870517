import { Injectable } from '@angular/core';
import { AngularFirestore } from 'angularfire2/firestore';
import { leftJoin, leftJoinDocument } from '../collectionJoin';
import { shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WachdiensteService {

  constructor(private firestore: AngularFirestore) { }

  createEntry(data) {
    data["organisationKey"] = localStorage.getItem("currentOrganisation");
    let key: string;
    let promise = new Promise<any>((resolve, reject) =>{
        this.firestore
            .collection("wachdienste")
            .add(data)
            .then(docRef => {
              key = docRef.id
            })
    });
    return key;
  }
  getEntries() { 
    return this.firestore.collection("wachdienste", ref => ref.where('organisationKey', '==', localStorage.getItem("currentOrganisation"))).snapshotChanges();
  }
  getJoinedEntries() {
    return this.firestore
    .collection('wachdienste', ref => ref.where('organisationKey', '==', localStorage.getItem("currentOrganisation")))
    .valueChanges({ idField: 'id' })
    .pipe(
      leftJoinDocument(this.firestore, 'wachleiter', 'einsatzkraefte'),
      shareReplay(1)
    );
  }
  updateEntry(key, data) {
    data["organisationKey"] = localStorage.getItem("currentOrganisation");
    return this.firestore.collection("wachdienste").doc(key).set(data);
  }
  getSingleEntry(key){   
    return this.firestore.collection("wachdienste").doc(key).snapshotChanges()
  }

 deleteEntry(key) {
  return this.firestore
      .collection("wachdienste")
      .doc(key)
      .delete();
}
}
