import { Injectable } from '@angular/core';
import { AngularFirestore } from 'angularfire2/firestore';

@Injectable({
  providedIn: 'root'
})
export class EinsatzkraefteService {
  constructor(private firestore: AngularFirestore) { }

  getEntries() {
    console.log("read einsatzkraefte");
    return this.firestore.collection("einsatzkraefte", ref => ref.where('organisationKey', '==', localStorage.getItem("currentOrganisation"))).snapshotChanges();
  }
  async createEntry(data) {
    data["organisationKey"] = localStorage.getItem("currentOrganisation");
    return this.firestore
      .collection("einsatzkraefte")
      .add(data)
  }
  updateEntry(key, data) {
    return this.firestore.collection("einsatzkraefte").doc(key).set(data);
  }
  deleteEntry(key) {
    return this.firestore
        .collection("einsatzkraefte")
        .doc(key)
        .delete();
  }

  


}
