<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="(isHandset$ | async) ? 'over' : 'side'"
      [opened]="(isHandset$ | async) === false">
    <mat-toolbar>Menü</mat-toolbar>

    <a href="/profile" >
      <div align="center">

        <img mat-card-avatar src="{{ photoURL }}" style="width: 100px; height: 100px;">
        <br>
        <b>{{displayName}}</b>
        
      </div>
      <br>
    </a>
    
    <mat-nav-list>
      <a mat-list-item href="/map" [routerLink]="['/map']" routerLinkActive="active"><mat-icon>map</mat-icon> Lagekarte</a>
      <a mat-list-item href="/einsaetze" [routerLink]="['/einsaetze']" routerLinkActive="active"><mat-icon>local_hospital</mat-icon> Einsätze</a>
      <a mat-list-item href="/wachbuch" [routerLink]="['/wachbuch']" routerLinkActive="active"><mat-icon>menu_book</mat-icon> Wachbuch</a>
      <a mat-list-item href="/fahrtenbuch" [routerLink]="['/fahrtenbuch']" routerLinkActive="active"><mat-icon>directions_car</mat-icon> Fahrtenbuch</a>
      <a mat-list-item href="/admin" [routerLink]="['/admin']" routerLinkActive="active"><mat-icon>settings</mat-icon> Admin</a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="primary">
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        *ngIf="isHandset$ | async">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <span>RescueDesk</span>
      <span class="example-spacer"></span>
      <button fxHide="false" fxHide.xs="true"class="example-spacer" mat-icon-button type="button" (click)="showOrganisationsSelect()">{{ currentOrganisation }}</button>
      <button fxHide="true" fxHide.xs="false" class="example-spacer" mat-icon-button type="button" (click)="showOrganisationsSelect()"><mat-icon aria-label="Side nav toggle icon">business</mat-icon></button>


      <button mat-icon-button (click)="openTrackingModule()"><mat-icon aria-label="Side nav toggle icon">my_location</mat-icon></button>     
      <button mat-icon-button (click)="auth.signOut()"><mat-icon aria-label="Side nav toggle icon">exit_to_app</mat-icon></button>
      </mat-toolbar>
    <!-- Add Content Here -->
    <ng-content></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>
