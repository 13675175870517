import { Injectable } from '@angular/core';
import { AngularFirestore } from 'angularfire2/firestore';
import { FormControl, FormGroup } from "@angular/forms";
import { leftJoinDocument } from '../collectionJoin';
import { shareReplay } from 'rxjs/operators';





@Injectable({
  providedIn: 'root'
})
export class FahrtenService {
  form = new FormGroup({        
    customerName: new FormControl(''),
    orderNumber: new FormControl(''),
    coffeeOrder: new FormControl(''), 
    completed: new FormControl(false)
  })


  constructor(private firestore: AngularFirestore) {}

  createEntry(data) {
    data["organisationKey"] = localStorage.getItem("currentOrganisation");
         this.firestore
            .collection("fahrten")
            .add(data)
            .then(docRef =>{
              return docRef.id
            })
  }
  getEntries() { 
    //return this.firestore.collection("fahrten").valueChanges({ idField: 'id' });
    return this.firestore.collection("fahrten", ref => ref.where('organisationKey', '==', localStorage.getItem("currentOrganisation"))).snapshotChanges();
  }
  getJoinedEntries() {
    return this.firestore
    .collection('fahrten', ref => ref.where('organisationKey', '==', localStorage.getItem("currentOrganisation")))
    .valueChanges({ idField: 'id' })
    .pipe(
      leftJoinDocument(this.firestore, 'einsatzmittel', 'einheiten'),
      shareReplay(1)
    )
    .pipe(
      leftJoinDocument(this.firestore, 'fahrzeugfuehrer', 'einsatzkraefte'),
      shareReplay(1)
    )
  }
  updateEntry(key, data) {
    data["organisationKey"] = localStorage.getItem("currentOrganisation");
    return this.firestore.collection("fahrten").doc(key).set(data);
  }
  getSingleEntry(key){   
    return this.firestore.collection("fahrten").doc(key).valueChanges()
  }

 deleteEntry(key) {
  return this.firestore
      .collection("fahrten")
      .doc(key)
      .delete();
}

}
