import { Component, OnInit } from '@angular/core';
import { OrganisationsService } from 'src/app/services/organisations.service';
import { AngularFireStorage } from 'angularfire2/storage';
import { FormGroup, FormBuilder, FormArray, Validators, FormControl } from '@angular/forms';
import { take } from 'rxjs/operators';
import { element } from 'protractor';
import {MatBottomSheet, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import { OpenQrBottomSheetComponent } from '../open-qr-bottom-sheet/open-qr-bottom-sheet.component';
import { EinsatzmittelService } from 'src/app/services/einsatzmittel.service';
import { EinsatzkraefteService } from 'src/app/services/einsatzkraefte.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {

  panelOpenState = false;

  organisationKey: string;
  headerURL: string;
  logoURL: string;
  newLogoFile: File;
  qrCodeSecret: string = "";
  //organisationName: string;

  pauseForm: boolean = false;
  organisationSubscription: Subscription
  formSubscription: Subscription
  einsatzkraefteSubscription: Subscription
  einheitenSubscription: Subscription

  randomStr(len, arr) { 
    var ans = ''; 
    for (var i = len; i > 0; i--) { 
        ans +=  
          arr[Math.floor(Math.random() * arr.length)]; 
    } 
    return ans; 
} 


  form: FormGroup = this.formBuildService.group({
    name: '',
    einheiten: this.formBuildService.array([]),
    einsatzkraefte: this.formBuildService.array([]),
    benutzer: this.formBuildService.array([]),
    benutzergruppen: this.formBuildService.array([]),
  },{ updateOn: 'blur' })
  //Benutzergruppen
  get gruppenForm() {
    return this.form.get('benutzergruppen') as FormArray
  }
  addGruppe() {
    const entry = this.formBuildService.group({
      bezeichnung: [],
      bemerkung: [],
      berechtigungen: [],
    })
    this.gruppenForm.push(entry)
    this.saveGruppen();
  }
  saveGruppen(){
    //Hier muss noch eine Cloud-Function getriggert werden, um die Ergebnisse auf die user-sammlung zu uebertragen
    this.organisationsService.updateEntry(this.organisationKey, {benutzergruppen: this.gruppenForm.value})
  }
  deleteGruppe(i) {
    this.gruppenForm.removeAt(i);
    this.saveGruppen();
  }  
   //Benutzer
   get benutzerForm() {
    return this.form.get('benutzer') as FormArray
  }
  addBenutzer() {
    const entry = this.formBuildService.group({
      email: [],
      gruppen: [],
      bemerkung: [],
    })
    this.benutzerForm.push(entry)
    this.saveBenutzer();
  }
  saveBenutzer(){
    //Hier muss noch eine Cloud-Function getriggert werden, um die Ergebnisse auf die user-sammlung zu uebertragen
    this.organisationsService.updateEntry(this.organisationKey, {benutzer: this.benutzerForm.value})
  }
  deleteBenutzer(i) {
    this.benutzerForm.removeAt(i);
    this.saveBenutzer();
  }  



   //Einsatzkraefte
   get einsatzkraefteForm() {
    return this.form.get('einsatzkraefte') as FormArray
  }
  addEinsatzkraft() {
    const entry = this.formBuildService.group({
      einsatzkraftKey: [],
      name: [],
      vorname: [],
      qualifikationen: [],
      organisationKey: this.organisationKey,
    })
    this.einsatzkkraefteService.createEntry(entry.value).then(docRef => {
      entry.patchValue({
        einsatzkraftKey: docRef.id,
      }) 
      this.einsatzkraefteForm.push(entry)
    }); 
  }
  saveAllEinsatzkraefte(){
    var data = this.einsatzkraefteForm.getRawValue();
    data.forEach(element => {
      var key = element.einsatzkraftKey;
      delete element.einsatzkraftKey;
      this.einsatzkkraefteService.updateEntry(key, element);
    });


    
    
    

  }
  deleteEinsatzkraft(i) {
    const key = this.einsatzkraefteForm.value[i].einsatzkraftKey;   
    this.einsatzkkraefteService.deleteEntry(key);
    this.einsatzkraefteForm.removeAt(i);
  }  
   //Einsatzverlauf
   get einheitenForm() {
    return this.form.get('einheiten') as FormArray
  }
  addEinheit() {
    const secret = this.randomStr(24, "1234567890abcdefghijklmnopqrstuvwxyz")
    const entry = this.formBuildService.group({
      einheitKey: [],
      funkrufname: [],
      enableOrtung: false,
      ortungSecret:  new FormControl({value: secret, disabled: true}, ),
      bemerkung: [],
      organisationKey: this.organisationKey,
      issi: [],
      kennzeichen: [],
      type: 0,
    })
    this.einheitenService.createEntry(entry.value).then(docRef => {
      entry.patchValue({
        einheitKey: docRef.id,
      }) 
      this.einheitenForm.push(entry)
    }); 
  }
  saveAllEinheiten(){
    var data = this.einheitenForm.getRawValue();
    data.forEach(element => {
      var key = element.einheitKey;
      delete element.einheitKey;
      this.einheitenService.updateEntry(key, element);
    });


    
    
    

  }
  deleteEinheit(i) {
    const key = this.einheitenForm.value[i].einheitKey;   
    this.einheitenService.deleteEntry(key);
    this.einheitenForm.removeAt(i);
  }

  showQR(secret: string) {
    
    
    this._bottomSheet.open(OpenQrBottomSheetComponent, {
      data: {secret: secret},
    });
  }


  constructor(
    private organisationsService: OrganisationsService,
    private storageService: AngularFireStorage,
    private formBuildService: FormBuilder,
    private _bottomSheet: MatBottomSheet,
    private einheitenService: EinsatzmittelService,
    private einsatzkkraefteService: EinsatzkraefteService,

  ) { }

  ngOnInit(): void {
    this.organisationKey = localStorage.getItem("currentOrganisation");
    //Load Organisation Details
    this.storageService.ref("organisation_logos/"+this.organisationKey+".png").getDownloadURL().pipe(take(1)).subscribe((url: string) => {
      this.logoURL = url;
    })
    this.storageService.ref("organisation_header/"+this.organisationKey+".png").getDownloadURL().pipe(take(1)).subscribe((url: string) => {
      this.headerURL = url;
    })  
    this.organisationSubscription = this.organisationsService.getSingleEntry(this.organisationKey).subscribe((result: any) => {
      this.pauseForm = true;
      //this.organisationName = result.payload.data().name;
      this.form.patchValue(result.payload.data(), {emitEvent: false}) 
      this.benutzerForm.clear();
      result.payload.data().benutzer.forEach(element => {
        var entry = this.formBuildService.group({
          email: element.email,
          bemerkung: element.bemerkung,
          gruppen: "",
        })
        entry.patchValue({
          gruppen: element.gruppen,
        })
        this.benutzerForm.push(entry) 
      });
      this.gruppenForm.clear();
      result.payload.data().benutzergruppen.forEach(element => {
        var entry = this.formBuildService.group({
          bezeichnung: element.bezeichnung,
          bemerkung: element.bemerkung,
          berechtigungen: "",
        })
        entry.patchValue({
          berechtigungen: element.berechtigungen,
        })
        
        this.gruppenForm.push(entry) 
      });
      this.pauseForm = false;
  
    })
    this.einheitenSubscription = this.einheitenService.getEntries().subscribe((result: any) => {
      this.pauseForm = true;
      this.einheitenForm.clear();
      result.forEach(element => {
        
        var entry = this.formBuildService.group({
          einheitKey: element.payload.doc.id,
          funkrufname: element.payload.doc.data().funkrufname,
          enableOrtung: element.payload.doc.data().enableOrtung,
          ortungSecret: new FormControl({value: element.payload.doc.data().ortungSecret, disabled: true}, ),
          
          bemerkung: element.payload.doc.data().bemerkung,
          organisationKey: element.payload.doc.data().organisationKey,
          issi: element.payload.doc.data().issi,
          kennzeichen: element.payload.doc.data().kennzeichen,
          type: element.payload.doc.data().type,
        })
        
        this.einheitenForm.push(entry) 
        

      });
      this.pauseForm = false;
    })
    this.einsatzkraefteSubscription = this.einsatzkkraefteService.getEntries().subscribe((result: any) => {
      this.pauseForm = true;
      this.einsatzkraefteForm.clear();
      result.forEach(element => {

        
        var entry = this.formBuildService.group({
          einsatzkraftKey: element.payload.doc.id,
          name: element.payload.doc.data().name,
          vorname: element.payload.doc.data().vorname,
          organisationKey: element.payload.doc.data().organisationKey,
          qualifikationen: ""
        })
        entry.patchValue({
          qualifikationen: element.payload.doc.data().qualifikationen,
        })
        //
        
        this.einsatzkraefteForm.push(entry) 
        

      });
      this.pauseForm = false;
      
    })

    this.formSubscription =  this.form.valueChanges.subscribe(data => {
      if (this.pauseForm == false && this.form.valid) {
        console.log("save");
        this.changeOrganisationName();
        this.saveBenutzer();
        this.saveAllEinsatzkraefte();
        this.saveGruppen();
        this.saveAllEinheiten();
      } else {
        console.log("not save");
        
      }
    })

  }
  ngOnDestroy(): void {
    this.einheitenSubscription.unsubscribe();
    this.einsatzkraefteSubscription.unsubscribe();
    this.organisationSubscription.unsubscribe();
    this.formSubscription.unsubscribe();
  }

  changeOrganisationName() {
    this.organisationsService.updateEntry(this.organisationKey, {name: this.form.value.name})
  }
  updateLogo(event) {
    const path = "organisation_logos/"+this.organisationKey+".png"
    const ref = this.storageService.ref(path)
    ref.put(event.target.files[0]).then(result => {
      this.storageService.ref(path).getDownloadURL().pipe(take(1)).subscribe((url: string) => {
        this.logoURL = url;
      })
    })
  }
  updateHeader(event) {
    const path = "organisation_header/"+this.organisationKey+".png"
    const ref = this.storageService.ref(path)
    ref.put(event.target.files[0]).then(result => {
      this.storageService.ref(path).getDownloadURL().pipe(take(1)).subscribe((url: string) => {
        this.headerURL = url;
      })
    })
  }

}
