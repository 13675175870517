import { Injectable } from '@angular/core';
import { AngularFirestore } from 'angularfire2/firestore';
import { shareReplay } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EinsaetzeService {

  constructor(
    private firestore: AngularFirestore,
    private http: HttpClient
  ) { }
  createEntry(data) {
    data["organisationKey"] = localStorage.getItem("currentOrganisation");
    let key: string;
    let promise = new Promise<any>((resolve, reject) =>{
        this.firestore
            .collection("einsaetze")
            .add(data)
            .then(docRef => {
              key = docRef.id
            })
    });
    return key;
  }
  getEntries() { 
    //return this.firestore.collection("fahrten").valueChanges({ idField: 'id' });
    //return this.firestore.collection("einsaetze").snapshotChanges();
    return this.firestore
    .collection('einsaetze', ref => ref.where('organisationKey', '==', localStorage.getItem("currentOrganisation")))
    .valueChanges({ idField: 'id' })
  }
  updateEntry(key, data) {
    data["organisationKey"] = localStorage.getItem("currentOrganisation");
    return this.firestore.collection("einsaetze").doc(key).set(data);
  }
  getSingleEntry(key){   
    return this.firestore.collection("einsaetze").doc(key).snapshotChanges()
  }
  lockEntry(key) {
    let data = {};
    data["meta_status"] = 1;
    this.firestore.collection("einsaetze").doc(key).update(data);
    let postData = {
      document_id: key
    }
    
    
    
    //start PDF-Generation
  }

 deleteEntry(key) {
  return this.firestore
      .collection("einsaetze")
      .doc(key)
      .delete();
}
}
