import { Injectable } from '@angular/core';
import { AngularFirestore } from 'angularfire2/firestore';



@Injectable({
  providedIn: 'root'
})
export class EinsatzmittelService {

  constructor(private firestore: AngularFirestore) { }

  getEntries() { 
    return this.firestore.collection("einheiten", ref => ref.where('organisationKey', '==', localStorage.getItem("currentOrganisation"))).snapshotChanges();
  }
  getEntriesForTracking() { 
    return this.firestore.collection("einheiten", ref => ref.where('organisationKey', '==', localStorage.getItem("currentOrganisation")).where('enableOrtung', '==', true)).snapshotChanges();
  }
  async createEntry(data) {
    data["organisationKey"] = localStorage.getItem("currentOrganisation");
    return this.firestore
      .collection("einheiten")
      .add(data)




  }
  updateEntry(key, data) {
    return this.firestore.collection("einheiten").doc(key).set(data);
  }
  deleteEntry(key) {
    return this.firestore
        .collection("einheiten")
        .doc(key)
        .delete();
  }

}
