import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { EinsatzkraefteService } from 'src/app/services/einsatzkraefte.service';
import { WachdiensteService } from 'src/app/services/wachdienste.service';
import { DatePipe } from '@angular/common';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { EinsatzkraefteFunktionenService } from 'src/app/services/einsatzkraefte-funktionen.service';

@Component({
  selector: 'app-wachbuch-form',
  templateUrl: './wachbuch-form.component.html',
  styleUrls: ['./wachbuch-form.component.css']
})
export class WachbuchFormComponent implements OnInit {

    wachdienstSubscription: Subscription
    formSubscription: Subscription
    //Observer


    pauseForm: boolean = false;
    
    einsatzkraefte;
    funktionen;
    

    //Form

    form: FormGroup = this.formBuildService.group({
      dienstbeginn: ['', [
        Validators.required,
        Validators.pattern('^(0[1-9]|[1-2][0-9]|3[0-1])\.(0[1-9]|1[0-2])\.[0-9]{4}\, (0[1-9]|1[0-9])\:([0-6][0-9])$'),
      ]],
      anmeldungRLST: ['', [
        Validators.pattern('^(0[1-9]|[1-2][0-9]|3[0-1])\.(0[1-9]|1[0-2])\.[0-9]{4}\, (0[1-9]|1[0-9])\:([0-6][0-9])$'),
      ]],
      abmeldungRLST: ['', [
        Validators.pattern('^(0[1-9]|[1-2][0-9]|3[0-1])\.(0[1-9]|1[0-2])\.[0-9]{4}\, (0[1-9]|1[0-9])\:([0-6][0-9])$'),
      ]],
      dienstende: ['', [
        Validators.pattern('^(0[1-9]|[1-2][0-9]|3[0-1])\.(0[1-9]|1[0-2])\.[0-9]{4}\, (0[1-9]|1[0-9])\:([0-6][0-9])$'),
      ]],
      wachleiter: '',
      wetterlage: '',
      lufttemperatur: '',
      wassertemperatur: '',
      bemerkung: '',
      einsatzkraefte: this.formBuildService.array([]),
  
    },{ updateOn: 'blur' }
    )

    get einsatzkraefteForm() {
      return this.form.get('einsatzkraefte') as FormArray
    }
    addEinsatzkraft() {
      const entry = this.formBuildService.group({
        einsatzkraftKey: ['', [
          Validators.required,
        ]],
        beginn: ['', [
          Validators.required,
          Validators.pattern('^(0[1-9]|[1-2][0-9]|3[0-1])\.(0[1-9]|1[0-2])\.[0-9]{4}\, (0[1-9]|1[0-9])\:([0-6][0-9])$'),
        ]],
        ende: ['', [
          Validators.pattern('^(0[1-9]|[1-2][0-9]|3[0-1])\.(0[1-9]|1[0-2])\.[0-9]{4}\, (0[1-9]|1[0-9])\:([0-6][0-9])$'),
        ]],
        funktion: []
      })
      this.einsatzkraefteForm.push(entry)
    }
    deleteEinsatzkraft(i) {
      this.einsatzkraefteForm.removeAt(i)
    }


  constructor(
    private einsatzkraefteService: EinsatzkraefteService, 
    private wachdiensteService:WachdiensteService, 
    private funktionenService:EinsatzkraefteFunktionenService,
    private formBuildService: FormBuilder,
    public datepipe: DatePipe
  ) {}

  @Input() entryId: string;
  @Output() showTable = new EventEmitter<any>();

  ngOnInit() {   
    this.einsatzkraefte = this.einsatzkraefteService.getEntries()
    this.funktionen     = this.funktionenService.getEntries()
    this.wachdienstSubscription = this.wachdiensteService.getSingleEntry(this.entryId).subscribe((element: any) => {           
        var formData;
        formData = element.payload.data()       
        if (formData.dienstbeginn != "") {
          const date = new Date(formData.dienstbeginn.seconds*1000);
          formData.dienstbeginn = moment(date).format('DD.MM.YYYY, HH:mm');    
        }
        if (formData.dienstende != "") {
          const date = new Date(formData.dienstende.seconds*1000);
          formData.dienstende = moment(date).format('DD.MM.YYYY, HH:mm');  
        }
        if (formData.anmeldungRLST != "") {
          const date = new Date(formData.anmeldungRLST.seconds*1000);
          formData.anmeldungRLST = moment(date).format('DD.MM.YYYY, HH:mm');  
        }
        if (formData.abmeldungRLST != "") {
          const date = new Date(formData.abmeldungRLST.seconds*1000);
          formData.abmeldungRLST = moment(date).format('DD.MM.YYYY, HH:mm');  
        }
        this.pauseForm = true;
        this.form.patchValue(formData, {emitEvent: false})     
        //Handle Einsatzkraefte
        console.log(this.einsatzkraefteForm.value);
        
        this.einsatzkraefteForm.clear()
        
        formData.einsatzkraefte.forEach((element: any) => {
          if (element.beginn != "") {
            const date = new Date(element.beginn.seconds*1000);
            element.beginn = moment(date).format('DD.MM.YYYY, HH:mm');  
          }
          if (element.ende != "") {
            const date = new Date(element.ende.seconds*1000);
            element.ende = moment(date).format('DD.MM.YYYY, HH:mm');  
          }
          var entry = this.formBuildService.group({
            einsatzkraftKey: element.einsatzkraftKey,
            beginn: element.beginn,
            ende: element.ende,
            funktion: element.funktion
          })
          this.einsatzkraefteForm.push(entry)  
          this.pauseForm = false;
        }); 
        


    })
    this.formSubscription =  this.form.valueChanges.subscribe(data => {
      if (this.pauseForm == false && this.form.valid) {
        console.log("save");
        this.saveChanges();
      } else {
        console.log("not save");
        
      }
    })
  }
  saveChanges() {
    const data = this.form.value
    if (data.dienstbeginn == "") {
      data.dienstbeginn = ""
    } else {
      var dateString = data.dienstbeginn
      const momentVariable = moment(dateString, 'DD.MM.YYYY, HH:mm');
      data.dienstbeginn = momentVariable.toDate()
    }
    if (data.dienstende == "") {
      data.dienstende = ""
    } else {
      var dateString = data.dienstende
      const momentVariable = moment(dateString, 'DD.MM.YYYY, HH:mm');
      data.dienstende = momentVariable.toDate()
    }
    if (data.anmeldungRLST == "") {
      data.anmeldungRLST = ""
    } else {
      var dateString = data.anmeldungRLST
      const momentVariable = moment(dateString, 'DD.MM.YYYY, HH:mm');
      data.anmeldungRLST = momentVariable.toDate()
    } 
    if (data.abmeldungRLST == "") {
      data.abmeldungRLST = ""
    } else {
      var dateString = data.abmeldungRLST
      const momentVariable = moment(dateString, 'DD.MM.YYYY, HH:mm');
      data.abmeldungRLST = momentVariable.toDate()
    }
    data.einsatzkraefte = data.einsatzkraefte.map(element =>{
      var newEntry = element     
      if (newEntry.beginn == "") {
        newEntry.beginn = ""
      } else {
        var dateString = newEntry.beginn
        const momentVariable = moment(dateString, 'DD.MM.YYYY, HH:mm');
        newEntry.beginn = momentVariable.toDate()
      }
      if (newEntry.ende == "") {
        newEntry.ende = ""
      } else {
        var dateString = newEntry.ende
        const momentVariable = moment(dateString, 'DD.MM.YYYY, HH:mm');
        newEntry.ende = momentVariable.toDate()
      }
      return newEntry
    })
    console.log(data);
    this.wachdiensteService.updateEntry(this.entryId, data)
  }
  ngOnDestroy() {
    this.wachdienstSubscription.unsubscribe()
    this.formSubscription.unsubscribe()
  }



}
